import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { TextStyles } from '@library/types';
import { getTextStyle } from '@library/utils/textStyling';
import clsx from 'clsx';

export type CopySize = 'caption' | 'xxxSmall' | 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge';

export interface CopyProps extends HTMLAttributes<HTMLParagraphElement> {
  size?: CopySize;
  text?: string;
  colour?: string;
  variant?: TextStyles;
  tooltip?: ReactNode;
  testId?: string;
}

export const CopyText = ({
  className,
  text,
  size = 'medium',
  colour,
  variant = 'paragraph',
  children,
  tooltip,
  testId,
  ...props
}: PropsWithChildren<CopyProps>) => {
  const sizeStyles = getTextStyle(size, variant);
  return (
    <p className={clsx(className, sizeStyles, colour && `text-${colour}`)} data-testid={testId} {...props}>
      {children || text}
      {tooltip && <div className={'mx-2'}>{tooltip} </div>}
    </p>
  );
};
