import { memo } from 'react';
import { CopyText } from '@library/components/atoms';
import { Pagination } from '@library/components/molecules';
import { flexRender } from '@tanstack/react-table';
import { Table } from '@tanstack/table-core';
import clsx from 'clsx';
import { t } from 'i18next';

import { Spinner } from '@shared/components/Spinner';

export interface TableProps {
  table: Table<unknown>;
  displayedRowCount: number;
  isEmpty?: boolean;
  noResults?: boolean;
  setPageIndex?: (num: number) => void;
  loading?: boolean;
  emptyState?: React.ReactNode;
  filterResults?: boolean;
  showPagination?: boolean;
}

export const DataTable = memo(
  ({
    table,
    displayedRowCount,
    isEmpty,
    setPageIndex,
    loading,
    emptyState,
    noResults,
    showPagination = true,
  }: TableProps) => {
    return (
      <>
        {loading && showPagination ? (
          <div className="w-full flex justify-center mt-64">
            <Spinner />
          </div>
        ) : (
          <>
            <div
              className={clsx('guide-table', displayedRowCount && 'min-h-[34.5rem]')}
              data-testid="treatment-guide-table"
            >
              <table className="w-full table-fixed">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} id="table-header">
                      {headerGroup.headers.map((header) => (
                        <th key={header.id} id={`table-col-${header.id}`} style={{ width: `${header.getSize()}px` }}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} id={`table-row-${row.id}`}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} id={`table-cell-${cell.id}`} style={{ width: `${cell.column.getSize()}px` }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              {!loading && isEmpty && <div className="mt-10">{emptyState}</div>}
            </div>
            {!!displayedRowCount && showPagination && (
              <div className="mb-36">
                <Pagination
                  totalRecords={displayedRowCount}
                  className="mt-10"
                  pageNumbers
                  size="small"
                  recordsPerPage={7}
                  onSetCurrentPage={(num: number) => {
                    setPageIndex && setPageIndex(num - 1);
                  }}
                />
              </div>
            )}

            {noResults && (
              <CopyText colour="foregroundNeutralSecondary" className="mt-10" size="medium">
                {t('practice.patientDashboard.noResults')}
              </CopyText>
            )}
          </>
        )}
      </>
    );
  },
);

DataTable.displayName = 'DataTable';
