import { PropsWithChildren, ReactElement, SetStateAction } from 'react';
import clsx from 'clsx';

import { useMediaQuery } from '@shared/hooks';

import { Icon } from '../Icon';

const transitionClasses = 'transform transition-all duration-300 ease-in-out';

type Props = {
  children: ReactElement;
  className?: string;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  isOpen?: boolean;
};

export const OffCanvas = ({ setIsOpen, isOpen, children }: Props & PropsWithChildren) => {
  const isDesktop = useMediaQuery('min-width: 768px');
  const slideTransitions = isDesktop
    ? {
        open: 'translate-x-0',
        close: 'translate-x-full',
      }
    : {
        open: 'translate-y-20',
        close: 'translate-y-full',
      };
  const classList = ['overflow-hidden'];
  if (isOpen) {
    document.body.classList.add(...classList);
  } else {
    document.body.classList.remove(...classList);
  }

  return (
    <div
      data-testid="offcanvas-wrapper"
      className={clsx(transitionClasses, isOpen ? 'visible' : 'invisible', 'fixed inset-0 z-50')}
    >
      {/* overlay */}
      <div
        data-testid="offcanvas-overlay"
        onClick={() => setIsOpen(false)}
        className={clsx(transitionClasses, isOpen && 'fixed inset-0 bg-black bg-opacity-20 z-offCanvasOverlay')}
      />
      {/* panel */}
      <div
        data-testid="offcanvas-panel"
        className={clsx(
          transitionClasses,
          isOpen ? slideTransitions.open : slideTransitions.close,
          'w-full md:max-w-[778px] right-0 z-offCanvasPanel absolute bg-white overflow-y-auto inset-y-0 rounded-3xl',
        )}
      >
        {/* close icon */}
        <div
          data-testid="offcanvas-panel-close"
          className="cursor-pointer items-center w-max ml-auto justify-center flex sticky right-4 top-4 p-2 rounded-full bg-primary-light"
          onClick={() => setIsOpen(false)}
        >
          <Icon name="x" size={24} className="stroke-primary-dark" />
        </div>

        {/* contents */}
        <div className="px-6 md:px-16">{children}</div>
      </div>
    </div>
  );
};
