import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { SortingState } from '@tanstack/react-table';

import { queries } from '@shared/data/practice/queries';
import {
  Accessor,
  DeletePracticeMembersArgs,
  FetchError,
  GetAttachmentsArgs,
  GetPatientOverview,
  GetPatientOverviewArgs,
  GetPracticeInstalments,
  GetPracticeInstalmentsArgs,
  GetPracticeMembers,
  GetPractitioners,
  GetTreatmentDefinition,
  GetTreatmentDefinitions,
  ITreatmentGuide,
  ModulusCheckResponse,
  PatchPracticeArgs,
  PatchPracticeMembersArgs,
  PatchPractitionersArgs,
  PatchTreatmentGuideArgs,
  PatchUserArgs,
  PlanStartDateResponse,
  PostAttachments,
  PostAttachmentsArgs,
  PostDividebuyRedirectArgs,
  PostPlanSubscriptionArgs,
  PostPracticeArgs,
  PostPracticeMembers,
  PostPracticeMembersArgs,
  PostPractitioners,
  PostPractitionersArgs,
  PostQuotationBody,
  PostQuotationResponse,
  PostSelectQuoteBody,
  PostSelectQuoteResponse,
  PostTreatmentGuides,
  PostTreatmentGuidesArgs,
  Practice,
  PracticePlans,
  PracticePublic,
  User,
} from '@shared/data/types';

import { mutations } from './mutations';

// NOTE: custom hooks make testing easier
// https://tanstack.com/query/v4/docs/react/guides/testing

// TODO: Remove QueryArgsAccessor2, accessor should not be passed in as a query key we can pass it into the queryFn function as seen in QueryArgsAccessor2

// request hooks for new backend API
/**
 * Returns the user details and the practices they belong to
 *  It also creates a user in the DB if one doesn't exist already
 */
export const useGetAuthUser = (accessor: Accessor, options?: { enabled: boolean }) =>
  useQuery<User, FetchError, { data: User }, string[]>({
    queryKey: [queries.GET_AUTH_USER.queryKey[0]],
    queryFn: (context) => queries.GET_AUTH_USER.queryFn(context, accessor),
    enabled: true,
    ...(options && options),
  });

export const usePatchAuthUser = () =>
  useMutation<void, FetchError, PatchUserArgs>({
    mutationFn: mutations.PATCH_AUTH_USER.mutationFn,
  });

export const useGetPracticeMembers = (practiceId: string) =>
  useQuery<GetPracticeMembers, FetchError, GetPracticeMembers, string[]>({
    queryKey: [queries.GET_PRACTICE_MEMBERS.queryKey[0], practiceId],
    queryFn: queries.GET_PRACTICE_MEMBERS.queryFn,
    enabled: Boolean(practiceId),
  });

export const usePostPracticeMembers = () =>
  useMutation<PostPracticeMembers, FetchError, PostPracticeMembersArgs>({
    mutationFn: mutations.POST_PRACTICE_MEMBERS.mutationFn,
  });

export const usePatchPracticeMembers = () =>
  useMutation<void, FetchError, PatchPracticeMembersArgs>({
    mutationFn: mutations.PATCH_PRACTICE_MEMBERS.mutationFn,
  });

export const useDeletePracticeMember = () =>
  useMutation<void, FetchError, DeletePracticeMembersArgs>({
    mutationFn: mutations.DELETE_PRACTICE_MEMBER.mutationFn,
  });

export const usePostPractice = () =>
  useMutation<{ data: { id: string } }, FetchError, PostPracticeArgs>({
    mutationFn: mutations.POST_PRACTICE.mutationFn,
  });

export const usePatchPractice = () =>
  useMutation<void, FetchError, PatchPracticeArgs>({
    mutationFn: mutations.PATCH_PRACTICE.mutationFn,
  });

export const useGetPractice = (
  practiceId: string,
  accessor: Accessor,
  options?: { onError?: () => void; enabled?: boolean },
) =>
  useQuery<Practice, FetchError, { data: Practice }, string[]>({
    queryKey: [queries.GET_PRACTICE.queryKey[0], practiceId, accessor],
    queryFn: (context) => queries.GET_PRACTICE.queryFn(context, practiceId, accessor),
    enabled: Boolean(practiceId),
    ...(options && options),
  });

export const useGetPractitioners = (practiceId: string) =>
  useQuery<GetPractitioners, FetchError, GetPractitioners, string[]>({
    queryKey: [queries.GET_PRACTITIONERS.queryKey[0], practiceId as string],
    queryFn: queries.GET_PRACTITIONERS.queryFn,
    enabled: Boolean(practiceId),
  });

export const usePostPractitioners = () =>
  useMutation<PostPractitioners, FetchError, PostPractitionersArgs>({
    mutationFn: mutations.POST_PRACTITIONERS.mutationFn,
  });

export const usePatchPractitioners = () =>
  useMutation<void, FetchError, PatchPractitionersArgs>({
    mutationFn: mutations.PATCH_PRACTITIONERS.mutationFn,
  });

export const useGetTreatmentGuide = (
  treatmentGuideId: string,
  accessor: Accessor,
  options?: { onSuccess: (res: { data: ITreatmentGuide }) => void },
  enabled?: boolean,
) =>
  useQuery<ITreatmentGuide, FetchError, { data: ITreatmentGuide }, string[]>({
    queryKey: [queries.GET_TREATMENT_GUIDES.queryKey[0], treatmentGuideId as string],
    queryFn: (context) => queries.GET_TREATMENT_GUIDES.queryFn(context, treatmentGuideId, accessor),
    enabled: enabled ?? Boolean(treatmentGuideId),
    ...(options && options),
  });
export const usePostTreatmentGuides = () =>
  useMutation<PostTreatmentGuides, FetchError, PostTreatmentGuidesArgs>({
    mutationFn: mutations.POST_TREATMENT_GUIDES.mutationFn,
  });

export const usePatchTreatmentGuides = (accessor: Accessor, options?: { onSuccess: () => void }) =>
  useMutation<void, FetchError, PatchTreatmentGuideArgs>({
    mutationFn: (data) => mutations.PATCH_TREATMENT_GUIDES.mutationFn(data, accessor),
    ...(options && options),
  });

/** Returns an array of high level treatment definitions if treatmentDefinitionId is undefined */
export const useGetTreatmentDefinition = (
  treatmentDefinitionId: string | undefined,
  accessor: Accessor,
  enabled: boolean,
) =>
  useQuery<GetTreatmentDefinition, FetchError, GetTreatmentDefinition, string[]>({
    queryKey: [queries.GET_TREATMENT_DEFINITION.queryKey[0], treatmentDefinitionId || ''],
    queryFn: (context) => queries.GET_TREATMENT_DEFINITION.queryFn(context, treatmentDefinitionId || '', accessor),
    enabled: enabled,
  });

export const useGetTreatmentDefinitions = () =>
  useQuery<GetTreatmentDefinitions, FetchError, GetTreatmentDefinitions>({
    queryKey: [queries.GET_TREATMENT_DEFINITIONS],
    queryFn: queries.GET_TREATMENT_DEFINITIONS.queryFn,
  });

export const usePostS3PutObject = (options?: {
  onSuccess: (data: PostAttachments, variables: PostAttachmentsArgs) => void;
}) =>
  useMutation<PostAttachments, FetchError, PostAttachmentsArgs>({
    mutationFn: mutations.POST_AUTH_S3_PUT_OBJECT.mutationFn,
    ...(options && options),
  });

export const useGetS3PutObject = (accessor: Accessor) =>
  useMutation<{ data: { url: string } }, FetchError, GetAttachmentsArgs>({
    mutationFn: (context) => mutations.GET_AUTH_S3_PUT_OBJECT.mutationFn(context, accessor),
  });

export const useGetPatientOverview = () =>
  useMutation<GetPatientOverview, FetchError, GetPatientOverviewArgs>({
    mutationFn: mutations.GET_PATIENT_OVERVIEW.mutationFn,
  });

export const useGetPatientOverviewCsv = () =>
  useMutation<string, FetchError, GetPatientOverviewArgs>({
    mutationFn: mutations.GET_PATIENT_OVERVIEW_CSV.mutationFn,
  });

export const useGetPracticePublic = (practiceId: string) =>
  useQuery<PracticePublic, FetchError, { data: PracticePublic }, string[]>({
    queryKey: [queries.GET_PRACTICE_PUBLIC.queryKey[0], practiceId as string],
    queryFn: queries.GET_PRACTICE_PUBLIC.queryFn,
    enabled: Boolean(practiceId),
  });

export const useGetPracticeInstalments = (
  { amount, practiceId }: GetPracticeInstalmentsArgs,
  options?: { enabled?: boolean },
  accessor?: Accessor,
) =>
  useQuery<GetPracticeInstalments, FetchError, GetPracticeInstalments, string[]>({
    queryKey: [queries.GET_PRACTICE_INSTALMENTS.queryKey[0], amount, practiceId],
    queryFn: (context) => queries.GET_PRACTICE_INSTALMENTS.queryFn(context, amount, practiceId, accessor),
    enabled: options?.enabled,
  });

export const usePostDividebuyRedirect = (options?: { onSuccess: (data: { data: { url: string } }) => void }) =>
  useMutation<{ data: { url: string } }, FetchError, PostDividebuyRedirectArgs, string[]>({
    mutationFn: (context) => mutations.POST_DIVIDEBUY_REDIRECT.mutationFn(context),
    ...(options && options),
  });

export const usePostMagicLink = (accessor: Accessor) =>
  useMutation<void, FetchError, { email: string }, string[]>({
    mutationFn: (data) => mutations.POST_MAGIC_LINK.mutationFn(data, accessor),
  });

export const usePostDividebuySoftSearch = () =>
  useMutation<{ data: { url: string } }, FetchError, { amount: number }, string[]>({
    mutationFn: (data) => mutations.POST_DIVIDEBUY_SOFT_SEARCH.mutationFn(data),
  });

export const useGetPlans = (practiceId: string) =>
  useQuery<PracticePlans[], FetchError, { data: { plans: PracticePlans[] } }, string[]>({
    queryKey: [queries.GET_PRACTICE_PLANS.queryKey[0], practiceId],
    queryFn: queries.GET_PRACTICE_PLANS.queryFn,
  });

export const useGetPlanStartDate = () =>
  useQuery<PlanStartDateResponse, FetchError, { data: { startDate: string } }, string[]>({
    queryKey: [queries.GET_PLAN_START_DATE.queryKey[0]],
    queryFn: queries.GET_PLAN_START_DATE.queryFn,
  });

export const useGetAddresses = () =>
  useMutation<{ data: { addresses: [] } }, FetchError, { postcode: string }>({
    mutationFn: mutations.GET_ADDRESSES.mutationFn,
  });

export const useGetModulusCheck = (sortCode: string, accountNumber: string, checkEnabled: { enabled: boolean }) =>
  useQuery<ModulusCheckResponse, FetchError, ModulusCheckResponse, string[]>({
    queryKey: [queries.GET_MODULUS_CHECK.queryKey[0], sortCode, accountNumber],
    queryFn: () => queries.GET_MODULUS_CHECK.queryFn(sortCode, accountNumber),
    enabled: checkEnabled.enabled,
  });

export const usePostDividebuyQuotation = (options?: {
  onError?: (error?: FetchError) => void;
  onSuccess?: () => void;
}) =>
  useMutation<PostQuotationResponse, FetchError, PostQuotationBody, string[]>({
    mutationFn: (data) => mutations.POST_DIVIDEBUY_QUOTATION.mutationFn(data),
    ...(options && { ...options, enabled: false }),
  });

export const usePostDividebuySelectQuote = () =>
  useMutation<PostSelectQuoteResponse, FetchError, PostSelectQuoteBody, string[]>({
    mutationFn: (data) => mutations.POST_DIVIDEBUY_SELECT_QUOTE.mutationFn(data),
  });

export const usePostPlanSubscription = () =>
  useMutation<void, FetchError, PostPlanSubscriptionArgs>({
    mutationFn: mutations.POST_PLAN_SUBSCRIPTION.mutationFn,
  });

const sortFieldMap: Record<string, string> = {
  'Name': 'patientName',
  'Plan': 'planName',
  'Monthly fee': 'planPrice',
  'Start date': 'subscriptionStartDate',
  'Status': 'subscriptionStatus',
  'Date of birth': 'patientDateOfBirth',
};

export const useGetSubscriptionOverview = (practiceId: string, subscriptionSorting: SortingState, enabled: boolean) =>
  useInfiniteQuery(
    ['subscriptionOverview'],
    ({ pageParam = 1 }) =>
      mutations.GET_SUBSCRIPTION_OVERVIEW.mutationFn({
        practiceId,
        page: pageParam,
        sortField: (subscriptionSorting && sortFieldMap[subscriptionSorting[0].id]) ?? 'subscriptionStartDate',
        sortDirection: !subscriptionSorting || subscriptionSorting[0].desc ? 'DESCENDING' : 'ASCENDING',
      }),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage?.pagination?.nextPage) return undefined;
        return parseInt(lastPage.pagination.nextPage.replace('?page=', ''));
      },
      enabled,
    },
  );

export const useGetPayoutOverview = (practiceId: string, payoutSorting: SortingState, enabled: boolean) =>
  useInfiniteQuery(
    ['payoutOverview'],
    ({ pageParam = 1 }) =>
      mutations.GET_PAYOUT_OVERVIEW.mutationFn({
        practiceId,
        page: pageParam,
        sortField: (payoutSorting && sortFieldMap[payoutSorting[0].id]) ?? 'month',
        sortDirection: !payoutSorting || payoutSorting[0].desc ? 'DESCENDING' : 'ASCENDING',
      }),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage?.pagination?.nextPage) return undefined;
        return parseInt(lastPage.pagination.nextPage.replace('?page=', ''));
      },
      enabled,
    },
  );
