import { CopyText } from '@library/components/atoms';
import { t } from 'i18next';

import { Heading } from '@shared/components/Heading';
import { Typography } from '@shared/components/Typography';

export const FinanceHowItWorks = () => (
  <div className="w-full flex flex-col lg:grid lg:grid-cols-2 col-span-12 mb-10 sm:my-20 justify-center mx-auto gap-12 sm:mt-24 place-items-stretch sm:px-40 md:px-0">
    <div className="w-full flex flex-col items-start gap-y-1 mx-auto px-6 md:px-[3.75rem]">
      <Heading className="mb-3" variant="h3">
        {t('patient.financePromoPage.howItWorksBlock.heading')}
      </Heading>
      <CopyText colour="neutral-secondary" size="large">
        {t('patient.financePromoPage.howItWorksBlock.subHeading')}
      </CopyText>
    </div>
    <div>
      <div className="grid gap-y-8 px-6 md:px-[3.75rem] lg:px-0 m:max-w-[26.25rem]">
        {steps.map(({ heading, description, number }) => (
          <div key={heading} className="flex gap-x-4 md:gap-x-6">
            <Typography
              element="span"
              variant="b16"
              className="flex items-center justify-center bg-neutral-blue-soft rounded-full min-w-8 h-8 text-neutral-primary"
            >
              {number}
            </Typography>
            <div>
              <Typography variant="b16" className="text-neutral-primary !font-medium mb-2">
                {heading}
              </Typography>
              <Typography className="text-neutral-secondary" variant="b16">
                {description}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const steps: {
  heading: string;
  description: string;
  number: number;
}[] = [
  {
    heading: t('patient.financePromoPage.howItWorksBlock.steps.step1.heading'),
    description: t('patient.financePromoPage.howItWorksBlock.steps.step1.description'),
    number: 1,
  },
  {
    heading: t('patient.financePromoPage.howItWorksBlock.steps.step2.heading'),
    description: t('patient.financePromoPage.howItWorksBlock.steps.step2.description'),
    number: 2,
  },
  {
    heading: t('patient.financePromoPage.howItWorksBlock.steps.step3.heading'),
    description: t('patient.financePromoPage.howItWorksBlock.steps.step3.description'),
    number: 3,
  },
];
