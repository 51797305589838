import { CellContext } from '@tanstack/react-table';
import { t } from 'i18next';

import { PlanAction, SubscriptionOverview } from '@shared/data/types';

import { CreateDecisionTableCell } from '../../../PatientTable/CellRenderers/DecisionRenderer/DecisionRenderer';

const getTooltipText = (planAction?: PlanAction) => {
  switch (planAction) {
    case PlanAction.ACTIVE:
      return t('practice.plansDashboard.statusTooltip.active');
    case PlanAction.PENDING:
      return t('practice.plansDashboard.statusTooltip.pending');
    case PlanAction.PAYMENT_FAILED:
      return t('practice.plansDashboard.statusTooltip.paymentFailed');
    default:
      return '';
  }
};

export const StatusRenderer = (props: CellContext<SubscriptionOverview, PlanAction>) => {
  const { subscriptionStatus } = props.row.original;
  const tooltipText = getTooltipText(subscriptionStatus);
  return CreateDecisionTableCell(subscriptionStatus, tooltipText);
};
