import { memo, RefObject, useEffect, useState } from 'react';
import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';
import { TextInput } from '@library/components/atoms/FormInputs';
import { Button, Selector } from '@library/components/molecules';
import { t } from 'i18next';
import { z } from 'zod';

import { validations } from '@shared/utils/validations';

import { QuotationApplicantionSchema } from '../QuotationApiForm/validations';

import { addressSchema } from './validations';

export type AddressFormSchema = z.infer<typeof addressSchema>;
export type Address = {
  addressline1: string;
  addressline2: string;
  posttown: string;
  postcode: string;
};

type PostcodeInputProps<T extends FieldValues> = {
  formRef?: RefObject<HTMLFormElement>;
  formProps: UseFormReturn<T>;
  searchByPostcode: (postcode: string) => void;
  addressOptions: Address[];
  disableForm?: boolean;
  postcodeError: string | null;
  placeholder?: string;
  fieldNamePrefix?: string;
  addressLine3?: boolean;
  postcodeFinderError?: string;
  testPrefix?: string;
};

export const PostcodeInputInner = <T extends AddressFormSchema | QuotationApplicantionSchema>({
  formProps,
  searchByPostcode,
  addressOptions,
  disableForm,
  postcodeError,
  placeholder,
  fieldNamePrefix = '',
  addressLine3,
  postcodeFinderError,
  testPrefix,
}: PostcodeInputProps<T>) => {
  const [selectedAddress, setSelectedAddress] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [postcodeValidation, setPostcodeValidation] = useState<string | null>(null);

  useEffect(() => {
    const addressLine1 = formProps.getValues(`${fieldNamePrefix}addressline1` as Path<T>);
    const posttown = formProps.getValues(`${fieldNamePrefix}posttown` as Path<T>);
    const postcode = formProps.getValues(`${fieldNamePrefix}postcode` as Path<T>);
    if (addressLine1 && posttown && postcode) {
      setSelectedAddress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searchByPostcode(searchTerm);
    formProps.clearErrors();
    if (searchTerm.length >= 6 && !validations.postcode.safeParse(searchTerm).success) {
      setPostcodeValidation(t('common.validations.postcode'));
    } else {
      setPostcodeValidation(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const Wrapper = disableForm ? 'div' : 'form';

  return (
    <Wrapper
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="w-full flex flex-col gap-6">
        <div>
          <Selector
            options={addressOptions.map((address) => {
              return {
                name: `${address.addressline1}, ${address.addressline2 ? address.addressline2 + ',' : ''} ${
                  address.posttown
                }, ${address.postcode}`,
                value: `${address.addressline1}, ${address.addressline2}, ${address.posttown}, ${address.postcode}`,
              };
            })}
            size={'medium'}
            onChange={(selection) => {
              const address = addressOptions.find(
                (option) =>
                  `${option.addressline1}, ${option.addressline2 ? option.addressline2 + ',' : ''} ${
                    option.posttown
                  }, ${option.postcode}` === selection.name,
              );
              setSelectedAddress(true);
              formProps.setValue(
                `${fieldNamePrefix}addressline1` as Path<T>,
                (address?.addressline1 || '') as PathValue<T, Path<T>>,
              );
              formProps.setValue(
                `${fieldNamePrefix}addressline2` as Path<T>,
                (address?.addressline2 || '') as PathValue<T, Path<T>>,
              );
              if (addressLine3) {
                formProps.setValue(`${fieldNamePrefix}addressline3` as Path<T>, '' as PathValue<T, Path<T>>);
              }

              formProps.setValue(
                `${fieldNamePrefix}posttown` as Path<T>,
                (address?.posttown || '') as PathValue<T, Path<T>>,
              );

              formProps.setValue(
                `${fieldNamePrefix}postcode` as Path<T>,
                (address?.postcode || '') as PathValue<T, Path<T>>,
              );
              setSearchTerm('');
            }}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            validationError={!selectedAddress ? postcodeError || postcodeValidation || postcodeFinderError : ''}
            labelText="Address finder"
            placeholder={placeholder}
            full
            hideArrowIcons
            errorDescription="Please enter a valid postcode"
            showMenu={addressOptions.length > 0}
            leftIcon="search"
            iconColour="text-foregroundNeutralTertiary"
            testPrefix={testPrefix}
            className="!text-foregroundNeutralTertiary"
          />
          {!selectedAddress && (
            <Button
              className="!p-0 !text-foregroundBrandPrimary !bg-transparent"
              appearance="ghost"
              mode="accent"
              size="small"
              text="Enter address manually"
              type="button"
              onClick={() => {
                setSelectedAddress(true);
                formProps.setValue(`${fieldNamePrefix}addressline1` as Path<T>, '' as PathValue<T, Path<T>>);
                formProps.setValue(`${fieldNamePrefix}addressline2` as Path<T>, '' as PathValue<T, Path<T>>);
                if (addressLine3) {
                  formProps.setValue(`${fieldNamePrefix}addressline3` as Path<T>, '' as PathValue<T, Path<T>>);
                }
                formProps.setValue(`${fieldNamePrefix}posttown` as Path<T>, '' as PathValue<T, Path<T>>);
                formProps.setValue(`${fieldNamePrefix}postcode` as Path<T>, '' as PathValue<T, Path<T>>);
              }}
            />
          )}
        </div>

        {selectedAddress && (
          <>
            <TextInput
              formProps={formProps as unknown as UseFormReturn<FieldValues>}
              name={`${fieldNamePrefix}addressline1` as Path<T>}
              labelText="Address Line 1"
              placeholder="123 High Street"
              id="addressline1"
              testId="line1"
              onBlur={() => {
                formProps.trigger('addressline1' as Path<T>);
              }}
            />
            <TextInput
              formProps={formProps as unknown as UseFormReturn<FieldValues>}
              name={`${fieldNamePrefix}addressline2` as Path<T>}
              labelText="Address Line 2 (optional)"
              placeholder=""
              id="addressline2"
              testId="line2"
              onBlur={() => {
                formProps.trigger('addressline2' as Path<T>);
              }}
            />
            {addressLine3 && (
              <TextInput
                formProps={formProps as unknown as UseFormReturn<FieldValues>}
                name={`${fieldNamePrefix}addressline3` as Path<T>}
                labelText="Address Line 3 (optional)"
                placeholder="Building name"
                id="addressline3"
                testId="line3"
                onBlur={() => {
                  formProps.trigger('addressline3' as Path<T>);
                }}
              />
            )}
            <TextInput
              formProps={formProps as unknown as UseFormReturn<FieldValues>}
              name={`${fieldNamePrefix}posttown` as Path<T>}
              labelText="Town"
              testId="town"
              placeholder="London"
              id="town"
              onBlur={() => {
                formProps.trigger('posttown' as Path<T>);
              }}
            />
            <TextInput
              formProps={formProps as unknown as UseFormReturn<FieldValues>}
              name={`${fieldNamePrefix}postcode` as Path<T>}
              labelText="Postcode"
              testId="postcode"
              placeholder="SW1A 1AA"
              id="postcode"
              onBlur={() => {
                formProps.trigger('postcode' as Path<T>);
              }}
            />
          </>
        )}
      </div>
    </Wrapper>
  );
};

export const PostcodeInput = memo(PostcodeInputInner) as <T extends FieldValues>(
  props: PostcodeInputProps<T>,
) => JSX.Element;
