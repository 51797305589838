import { memo } from 'react';
import { CopyText } from '@library/components/atoms';
import { Illustration, IllustrationProps } from '@library/components/atoms/Illustration';
import { Button, ButtonProps } from '@library/components/molecules';
import clsx from 'clsx';

import { getHeadingVariant, getParagraphSize, getSizeClasses } from './utils';

const baseClasses = `
  w-full
  flex
  flex-col
  items-center
  justify-center
  rounded-2xl
  bg-white
  text-center
`;

const backgroundClasses = `
  !bg-backgroundNeutralSoft
  !border
  !border-borderNeutralDefault
`;

export interface EmptyProps {
  background?: boolean;
  size: 'small' | 'medium' | 'large';
  illustration: IllustrationProps['type'];
  title: string;
  description: string;
  buttonText?: string;
  handleButtonClick?: () => void;
  buttonIcon?: ButtonProps['leftIcon'];
  buttonAppearance?: ButtonProps['appearance'];
}

export const Empty = memo(
  ({
    size,
    background,
    illustration,
    title,
    description,
    buttonText,
    buttonIcon,
    handleButtonClick,
    buttonAppearance = 'primary',
  }: EmptyProps) => {
    const sizeClasses = getSizeClasses(size);
    const headingSize = getHeadingVariant(size);
    const paragraphSize = getParagraphSize(size);

    return (
      <div data-testid="empty-wrapper" className={clsx(baseClasses, sizeClasses, background && backgroundClasses)}>
        <div className={clsx(size === 'small' ? 'mb-4' : 'mb-5')}>
          <Illustration size={size} type={illustration} />
        </div>
        <div
          className={clsx(
            'flex flex-col items-center justify-center w-80',
            size === 'small' ? 'gap-y-1 mb-4' : 'gap-y-2 mb-6',
          )}
        >
          <CopyText variant="heading" size={headingSize} colour="foregroundNeutralPrimary">
            {title}
          </CopyText>
          <CopyText size={paragraphSize} colour="foregroundNeutralSecondary">
            {description}
          </CopyText>
        </div>
        {buttonText && handleButtonClick && (
          <Button
            size={size}
            text={buttonText}
            appearance={buttonAppearance}
            mode="accent"
            onClick={handleButtonClick}
            leftIcon={buttonIcon}
          />
        )}
      </div>
    );
  },
);

Empty.displayName = 'Empty';
