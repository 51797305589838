import { useState } from 'react';
import { TableCell } from '@library/components/atoms/TableCell';
import { Tag, Tooltip } from '@library/components/molecules';
import { CellContext } from '@tanstack/react-table';
import { t } from 'i18next';
import { DateTime } from 'luxon';

import { PatientAction, PatientOverview, PaymentAction, PlanAction } from '@shared/data/types';

const getTooltipText = (
  patientAction?: PatientAction,
  reason?: string | null,
  fulfilledAt?: string,
  followUpEmailSentAt?: string,
  acceptedAt?: string,
) => {
  const date = fulfilledAt ? DateTime.fromISO(fulfilledAt).toFormat('D') : '';
  if (patientAction === PatientAction.DECLINED && reason) {
    return reason;
  } else if (patientAction === PatientAction.PAID_WITH_FINANCE) {
    return t('practice.patientDashboard.table.financeApprovedTooltip', {
      acceptedAt: acceptedAt ? ` on ${DateTime.fromISO(acceptedAt).toFormat('dd LLL yyyy')}` : '',
    });
  } else if (patientAction === PatientAction.FINANCE_PROCESSED) {
    return t('practice.patientDashboard.table.financeProcessedTooltip', { date });
  } else if (patientAction === PatientAction.FINANCE_DECLINED) {
    return t('practice.patientDashboard.table.financeDeclinedTooltip');
  } else if (patientAction === PatientAction.GUIDE_BOUNCED) {
    return t('practice.patientDashboard.table.emailBouncedTooltip');
  } else if (patientAction === PatientAction.FOLLOW_UP_SENT) {
    return t('practice.patientDashboard.table.followUpSentTooltip', {
      followUpEmailSentAt: followUpEmailSentAt ? DateTime.fromISO(followUpEmailSentAt).toFormat('dd LLL yyyy') : '',
    });
  }
  return;
};

export const DecisionRendererNewDS = (props: CellContext<PatientOverview, unknown>) => {
  const { patientAction, reason, followUpEmailSentAt, fulfilledAt, acceptedAt } = props.row.original;
  const tooltipText = getTooltipText(patientAction, reason, fulfilledAt, followUpEmailSentAt, acceptedAt);
  return CreateDecisionTableCell(patientAction, tooltipText);
};

export const CreateDecisionTableCell = (action?: PatientAction | PlanAction | PaymentAction, tooltipText?: string) => {
  const [tagWidth, setTagWidth] = useState<number>(0);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <TableCell>
      <div
        ref={(el) => {
          if (el) {
            setTagWidth(el.offsetWidth);
          }
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Tag
          size="medium"
          text={action}
          template={action}
          tooltip={
            tooltipText ? (
              <Tooltip position="top" title={tooltipText} arrow offsetWidth={tagWidth} externalHover={isHovered} />
            ) : undefined
          }
        />
      </div>
    </TableCell>
  );
};
