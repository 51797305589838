import { forwardRef } from 'react';
import { CopyText, Icon, Link } from '@library/components/atoms';
import { State } from '@library/types';
import clsx from 'clsx';
import { CustomContentProps, SnackbarContent } from 'notistack';

import {
  getNotificationClasses,
  getNotificationIcon,
  getNotificationIconColor,
  getNotificationTextSize,
} from './utils';

const baseClasses = `
  w-fit flex items-center justify-start bg-backgroundBaseWhite border border-borderNeutralSoft
`;

export interface NotificationProps extends CustomContentProps {
  key?: string;
  size: 'small' | 'medium' | 'large';
  state: State;
  text: {
    title: string;
    description?: string;
  };
  link?: {
    text: string;
    path: string;
  };
  closeIcon?: boolean;
  onClose: () => void;
}

export const Notification = forwardRef<HTMLDivElement, NotificationProps>(({ ...props }, ref) => {
  const textSize = getNotificationTextSize(props.size);
  const iconName = getNotificationIcon(props.state);
  return (
    <SnackbarContent
      data-testid={`notification-${props.state}-${props.size}`}
      ref={ref}
      className={clsx(baseClasses, getNotificationClasses(props.size))}
    >
      <Icon
        testId={`icon-${props.state}-${iconName}`}
        iconName={iconName}
        size={textSize}
        color={getNotificationIconColor(props.state)}
      />
      <CopyText size={textSize} className="!font-medium !leading-none">
        {props.text.title}
      </CopyText>
      {props.text.description && (
        <CopyText size={textSize} className="!leading-none">
          {props.text.description}
        </CopyText>
      )}
      {props.link && props.link.text && props.link.path && (
        <Link
          className="!font-medium"
          appearance="secondary"
          role="link"
          text={props.link.text}
          underline
          to={props.link.path}
          size={textSize}
        />
      )}
      {props.closeIcon && (
        <span data-testid="notification-close" onClick={props.onClose} className="cursor-pointer">
          <Icon iconName="close" size={textSize} color="text-foregroundNeutralQuaternary" />
        </span>
      )}
    </SnackbarContent>
  );
});

Notification.displayName = 'Notification';
