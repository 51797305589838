import { Dispatch, memo, SetStateAction } from 'react';
import { DataTable } from '@library/components/molecules/DataTable/DataTable';
import { Empty } from '@library/components/organisms';
import { SortingState, Table } from '@tanstack/react-table';
import { t } from 'i18next';

import { SubscriptionOverview } from '@shared/data/types';

import { useTable } from './useTable';

export interface SubscriptionTableProps {
  subscriptions: SubscriptionOverview[];
  subscriptionsLoading: boolean;
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
}

export const SubscriptionTable = memo(
  ({ subscriptions, subscriptionsLoading, sorting, setSorting }: SubscriptionTableProps) => {
    const { table } = useTable({
      subscriptionOverview: subscriptions,
      sorting,
      setSorting,
    });

    const noSubscriptionsExist = !subscriptionsLoading && !subscriptions.length;

    return (
      <div data-testid="subscription-table-wrapper" className="w-full">
        <DataTable
          table={table as Table<unknown>}
          displayedRowCount={subscriptions.length}
          isEmpty={noSubscriptionsExist && !subscriptionsLoading}
          loading={subscriptionsLoading}
          showPagination={false}
          emptyState={
            <Empty
              background
              description={t('practice.plansDashboard.patients.emptyState')}
              illustration="confused1"
              size="medium"
              title={t('practice.plansDashboard.patients.emptyTitle')}
            />
          }
        />
      </div>
    );
  },
);

SubscriptionTable.displayName = 'SubscriptionTable';
