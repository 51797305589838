import { useEffect, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { CopyText } from '@library/components/atoms';
import PageContentWrapper from '@library/components/atoms/PageContentWrapper';
import { Empty } from '@library/components/organisms';
import DividebuyEligibilityResults from '@library/components/organisms/DividebuyEligibilityResults';
import QuotationApiForm from '@library/components/organisms/QuotationApiForm';
import { scrollToTop } from '@library/utils';
import { captureMessage } from '@sentry/react';
import { t } from 'i18next';

import { Spinner } from '@shared/components/Spinner';
import {
  useGetPracticeInstalments,
  useGetTreatmentGuide,
  usePostDividebuyQuotation,
  usePostDividebuySelectQuote,
} from '@shared/data/practice/hooks';
import { Accessor, existingCustomer, PostQuotationBody } from '@shared/data/types';
import { useDividebuyLoanApplicationStore } from '@shared/stores/dividebuyLoanApplicationStore';
import { patientDashboardPage } from '@shared/utils/urls';

export const DividebuyLoanApplication = () => {
  const navigate = useNavigate();
  const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
  const practiceId = treatmentGuideId.split(':')[0];

  const { term } = useDividebuyLoanApplicationStore();
  const {
    data: postQuotationResponse,
    isLoading: postQuotationIsLoading,
    mutate: postQuotation,
    error: postQuotationError,
  } = usePostDividebuyQuotation();

  const { isLoading: postSelectQuoteIsLoading, mutate: postSelectQuote } = usePostDividebuySelectQuote();

  const onContinueToDividebuy = (quoteId: string) => {
    postSelectQuote(
      { quoteId },
      {
        onSuccess: (res) => window.location.replace(res.data.checkoutUrl),
      },
    );
  };

  const onSubmitForm = (body: PostQuotationBody) => {
    scrollToTop();
    setTimeout(() => {
      postQuotation(body);
    }, 300);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const { data: treatmentGuide, isLoading: treatmentGuideIsLoading } = useGetTreatmentGuide(
    treatmentGuideId,
    Accessor.PATIENT,
  );

  const finalBalance = parseInt(treatmentGuide?.data?.price || '0') - parseInt(treatmentGuide?.data?.deposit || '0');

  const {
    data: instalments,
    isLoading,
    refetch: getInstalments,
  } = useGetPracticeInstalments({
    amount: String(finalBalance / 100),
    practiceId,
  });

  useEffect(() => {
    if (finalBalance > 0 && practiceId) getInstalments();
  }, [finalBalance, practiceId, getInstalments]);

  const instalmentsData = useMemo(() => instalments?.data.terms || [], [instalments?.data]);

  const availableInstalments = useMemo(
    () => instalmentsData.filter(({ available, type }) => type !== 'representative_example' && available != false),
    [instalmentsData],
  );

  const isPartialMatch = postQuotationError?.data?.quotesResponse?.title === existingCustomer;

  useEffect(() => {
    if (postQuotationError && !isPartialMatch) {
      captureMessage('Eligibility check error', {
        level: 'debug',
        extra: {
          treatmentGuideId,
          dividebuyResponseTitle: postQuotationError?.data?.quotesResponse?.title,
          error: postQuotationError?.message,
        },
        tags: {
          feature: 'quotation api error',
          userType: 'patient',
        },
      });
    }
  }, [postQuotationError, isPartialMatch, treatmentGuideId]);

  if (postQuotationIsLoading) {
    return (
      <PageContentWrapper>
        <div className="flex flex-col items-center text-center min-h-[calc(100vh-7.375rem)]">
          <Spinner centered={false} />
          <CopyText variant="heading" size="xSmall" className="mt-9">
            {t('patient.patientCheckoutPage.quotationForm.loadingTitle')}
          </CopyText>
          <CopyText size="large" colour="foregroundNeutralSecondary" className="mt-3">
            {t('patient.patientCheckoutPage.quotationForm.loadingDescription')}
          </CopyText>
        </div>
      </PageContentWrapper>
    );
  }

  if (treatmentGuideIsLoading || isLoading) return <Spinner />;

  if (postQuotationError && !isPartialMatch) {
    return (
      <div className="mb-6">
        <Empty
          illustration="confused1"
          size="large"
          title={t('common.errorPage.title')}
          description={t('common.errorPage.message')}
          handleButtonClick={() => navigate(patientDashboardPage)}
          buttonText={t('patient.checkoutPage.goToMyGuide')}
          buttonAppearance="subtle"
        />
      </div>
    );
  }

  if (postQuotationResponse) {
    return (
      <PageContentWrapper>
        <DividebuyEligibilityResults
          instalmentsData={availableInstalments}
          requestedQuote={postQuotationResponse?.data?.quotesResponse?.data?.requested_quote}
          alternativeQuotes={postQuotationResponse?.data?.quotesResponse?.data?.alternative_quotes}
          onContinueToDividebuy={onContinueToDividebuy}
          postSelectQuoteIsLoading={postSelectQuoteIsLoading}
        />
      </PageContentWrapper>
    );
  }

  return (
    <PageContentWrapper className="!md:px-0">
      {treatmentGuideId && term ? (
        <QuotationApiForm
          term={term}
          treatmentGuideId={treatmentGuideId}
          onSubmitForm={onSubmitForm}
          isPartialMatch={isPartialMatch}
        />
      ) : (
        <Navigate to={patientDashboardPage} />
      )}
    </PageContentWrapper>
  );
};
