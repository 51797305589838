import { ReactNode } from 'react';

export type HandleOnProgressArgs = {
  activeStageId: string;
  stageOrder: string[];
  journeyId: string;
  patientId: string;
};
export type HandleOnProgress = (args: HandleOnProgressArgs) => void;

/**
 * This enum holds constant values to represent a generic step a patient can be at in their treatment journey
 * These are used to derive the content of the following Patient table columns:
 * Current Step, Status, Time Elapsed, Recommended Action
 */
export enum UserStatus {
  YET_TO_LOGIN = 'YET_TO_LOGIN',
  NOT_BOOKED = 'NOT_BOOKED',
  IN_ASSESSMENT_STAGE = 'IN_ASSESSMENT_STAGE',
  MID_TREATMENT = 'MID_TREATMENT',
  IN_MAINTENANCE_REVIEW = 'IN_MAINTENANCE_REVIEW',
  IN_SUMMARY = 'IN SUMMARY',
  COMPLETED = 'COMPLETED',
  /** When a user has declined treatment - no logic exists yet */
  DROPPED_OFF = 'DROPPED_OFF',
  /** The user has incomplete data i.e. No journeys or active stage */
  MALFORMED = 'MALFORMED',
}

export type TreatmentName =
  | 'adhesive-bridge'
  | 'clear-aligners'
  | 'composite-bonding'
  | 'crown'
  | 'crown-supported-bridge'
  | 'implant'
  | 'dentures'
  | 'extraction'
  | 'filling'
  | 'fixed-braces'
  | 'implant-retained-bridge'
  | 'implant-retained-dentures'
  | 'cerec-crown'
  | 'inlays-onlays'
  | 'periodontal-treatment'
  | 'root-canal'
  | 'routine-hygienist'
  | 'teeth-whitening-at-home'
  | 'teeth-whitening-in-practice'
  | 'veneers';

export const TreatmentNames = {
  'adhesive-bridge': 'Adhesive bridge',
  'clear-aligners': 'Clear aligners',
  'composite-bonding': 'Composite bonding',
  'crown': 'Crown',
  'crown-supported-bridge': 'Crown-supported bridge',
  'implant': 'Dental implant',
  'dentures': 'Dentures',
  'extraction': 'Extraction',
  'filling': 'Filling',
  'fixed-braces': 'Fixed braces',
  'implant-retained-bridge': 'Implant retained bridge',
  'implant-retained-dentures': 'Implant retained dentures',
  'cerec-crown': 'In-practice crown',
  'inlays-onlays': 'Inlays/onlays',
  'periodontal-treatment': 'Periodontal treatment',
  'root-canal': 'Root canal treatment',
  'routine-hygienist': 'Hygienist',
  'teeth-whitening-at-home': 'Teeth whitening (at home)',
  'teeth-whitening-in-practice': 'Teeth whitening (in practice)',
  'veneers': 'Veneers',
};

export interface ProgressConfirmationContent {
  heading: string;
  subheading: string | ReactNode;
  subheading2?: string;
  ctaConfirmLabel: string;
  body?: ReactNode;
  ctaConfirmOnClick?: () => void;
  ctaConfirmEventName?: string;
}
