import { useCallback, useMemo } from 'react';
import { enqueueSnackbar } from 'notistack';

import { ActionMenu } from '@practice/components/ActionMenu';
import { MenuItemTag } from '@practice/components/MenuItemTag';
import { IconNameType } from '@shared/components/Icon';
import { usePatchTreatmentGuides } from '@shared/data/practice/hooks';
import { Accessor, PatientOverview, UpdatePatientOverview } from '@shared/data/types';
import { useTreatmentGuideContext } from '@shared/hooks';
import { usePracticeMixpanel } from '@shared/hooks/usePracticeMixpanel/usePracticeMixpanel';

import { showLastViewedDate } from '../../utils';

export type ActionRendererProps = {
  data: PatientOverview;
  useBoundingRect?: boolean;
  updatePatientOverview?: UpdatePatientOverview;
  practiceName: string;
};
export const ActionRenderer = ({ data, updatePatientOverview, practiceName }: ActionRendererProps) => {
  const treatmentGuidetreatmentGuideContext = useTreatmentGuideContext();

  const { trackPracticeEvent } = usePracticeMixpanel();
  const { treatmentGuideId, firstName, archived, lastSentAt, canEdit } = data || {};

  const patchTreatmentGuide = usePatchTreatmentGuides(Accessor.PRACTICE_MEMBER);

  const showSnackbar = (message: string) => {
    enqueueSnackbar(message, {
      autoHideDuration: 3000,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      variant: 'notistack',
      testId: 'treatment-guide-archive',
      type: 'success',
    });
  };

  const trackIsArchived = useCallback(() => {
    trackPracticeEvent('Guide archived', {
      'Practice name': practiceName,
      'Date created': data.createdAt,
      'Patient decision': data.patientAction,
    });
  }, [data.createdAt, data.patientAction, practiceName, trackPracticeEvent]);

  const updateIsArchived = useMemo(
    () => (archived: boolean) => {
      patchTreatmentGuide.mutate(
        {
          id: treatmentGuideId,
          archived,
        },
        {
          onSuccess: () => {
            showSnackbar(
              !archived
                ? `${firstName}'s guide has been unarchived`
                : `${firstName}'s guide has been archived. They no longer have access`,
            );
            !archived && trackIsArchived();
            updatePatientOverview?.(treatmentGuideId, true);
          },
        },
      );
    },
    [treatmentGuideId, patchTreatmentGuide, firstName, updatePatientOverview, trackIsArchived],
  );

  const resend = useMemo(
    () => () => {
      patchTreatmentGuide.mutate(
        {
          id: treatmentGuideId,
          resend: true,
        },
        {
          onSuccess: () => {
            showSnackbar(`${data.firstName}'s guide has been re-sent`);
            trackPracticeEvent('Invite re-sent');
            updatePatientOverview?.(treatmentGuideId);
          },
        },
      );
    },
    [patchTreatmentGuide, updatePatientOverview, trackPracticeEvent, data.firstName, treatmentGuideId],
  );

  const menuItems = useMemo(() => {
    const array = [];

    const url = `${window.location.origin}/practice/view-treatment-guide/${treatmentGuideId}`;

    if (!archived) {
      if (canEdit) {
        array.push({
          icon: 'edit' as IconNameType,
          testId: 'update',
          label: 'Guide details',
          onClick: () => {
            treatmentGuidetreatmentGuideContext?.setTreatmentGuideModalData({ treatmentGuideId, isOpen: true });
          },
        });
      }
      array.push({
        icon: 'eye' as IconNameType,
        testId: 'view',
        label: 'Patients view',
        onClick: () => {
          window.open(url, '_blank');
        },
      });
      array.push({
        icon: 'send' as IconNameType,
        testId: 'resend',
        label: 'Re-send',
        metaData: lastSentAt ? <MenuItemTag timeSent={showLastViewedDate(lastSentAt) || 'Unknown'} /> : undefined,
        onClick: resend,
      });

      array.push({
        icon: 'archive' as IconNameType,
        testId: 'archive',
        label: 'Archive guide',
        onClick: () => {
          updateIsArchived(true);
        },
      });
    }
    if (archived) {
      array.push({
        icon: 'archive' as IconNameType,
        testId: 'unarchive',
        label: 'Unarchive guide',
        onClick: () => {
          updateIsArchived(false);
        },
      });
    }
    return array;
  }, [treatmentGuidetreatmentGuideContext, treatmentGuideId, archived, canEdit, updateIsArchived, resend, lastSentAt]);

  return (
    <div className="flex justify-end w-full">
      <ActionMenu menuItems={menuItems} />
    </div>
  );
};
