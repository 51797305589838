import { Avatar } from '@library/components/atoms/Avatar';
import { TableCell } from '@library/components/atoms/TableCell';
import { Label } from '@library/components/molecules';
import { CellContext } from '@tanstack/react-table';
import clsx from 'clsx';

import { PatientOverview } from '@shared/data/types';
import { useTreatmentGuideContext } from '@shared/hooks';

import { elipsis } from '../TextRenderer/TextRenderer';

export const PatientRendererNewDS = (props: CellContext<PatientOverview, unknown>) => {
  const value = props.getValue() as string;
  const treatmentGuideContext = useTreatmentGuideContext();
  return (
    <TableCell>
      <div
        data-testid="patient-link"
        className={clsx('flex gap-2 items-center !cursor-pointer pointer-events-auto', elipsis)}
        onClick={() => {
          if (props?.row.original.canEdit) {
            treatmentGuideContext?.setTreatmentGuideModalData({
              treatmentGuideId: props?.row.original.treatmentGuideId,
              isOpen: true,
            });
          }
        }}
      >
        <div className="w-8">
          <Avatar text={value[0]} size={32} />
        </div>
        <Label
          truncate={false}
          className="pointer-events-none whitespace-normal"
          appearance="secondary"
          size="small"
          labelText={value}
          labelStrong={false}
        />
      </div>
    </TableCell>
  );
};
