import { CopyText } from '@library/components/atoms';
import { Button } from '@library/components/molecules';
import CheckoutPatientDecisionButton from '@library/components/molecules/CheckoutPatientDecisionButton';
import { t } from 'i18next';

import { CheckoutNotGoingAheadOptions } from '@patient/components/CheckoutNotGoingAheadOptions';
import { CheckoutPatientDecisionContainer } from '@patient/components/CheckoutPatientDecisionBlock';
import { NotGoingAheadReason } from '@shared/data/types';

interface CheckoutPatientDecisionBlockProps {
  financeOnly: boolean;
  isPatientNotSure: boolean;
  setIsPatientNotSure: (value: boolean) => void;
  notSureReason?: NotGoingAheadReason;
  setNotSureReason: (value: NotGoingAheadReason) => void;
  onClickConfirmNotSureReason: () => void;
  onClickPayInPracice: () => void;
  onClickImInterested: () => void;
  noPriceOrEstimated: boolean;
}

/* Allows the patients to make a decision */
export const CheckoutPatientDecisionBlock = ({
  financeOnly,
  isPatientNotSure,
  setIsPatientNotSure,
  notSureReason,
  setNotSureReason,
  onClickConfirmNotSureReason,
  onClickPayInPracice,
  onClickImInterested,
  noPriceOrEstimated,
}: CheckoutPatientDecisionBlockProps) => {
  if (financeOnly) return null;

  let decisions = [
    {
      icon: t('patient.patientCheckoutPage.decisions.notSureIcon'),
      labelText: t('patient.patientCheckoutPage.decisions.notSureLabel'),
      helperText: t('patient.patientCheckoutPage.decisions.notSureHelper'),
      onClick: () => setIsPatientNotSure(true),
      testId: 'not-sure-button',
    },
  ];

  if (noPriceOrEstimated) {
    // Patient can express interest
    decisions = [
      {
        icon: t('patient.patientCheckoutPage.decisions.interestedIcon'),
        labelText: t('patient.patientCheckoutPage.decisions.interestedLabel'),
        helperText: t('patient.patientCheckoutPage.decisions.interestedHelper'),
        onClick: () => onClickImInterested(),
        testId: 'interested-button',
      },
      ...decisions,
    ];
  } else {
    // Patient can choose to pay in practice
    decisions = [
      {
        icon: t('patient.patientCheckoutPage.decisions.inPracticePaymentIcon'),
        labelText: t('patient.patientCheckoutPage.decisions.inPracticePaymentLabel'),
        helperText: t('patient.patientCheckoutPage.decisions.inPracticePaymentHelper'),
        onClick: () => onClickPayInPracice(),
        testId: 'pay-in-practice-button',
      },
      ...decisions,
    ];
  }

  return (
    <div data-testid="patient-decision-block">
      {!isPatientNotSure && (
        <div className="flex flex-col gap-4">
          <CopyText variant="heading" size="xxxSmall">
            {t('patient.patientCheckoutPage.letUsKnowHeading')}
          </CopyText>
          {decisions.map((button, index) => (
            <CheckoutPatientDecisionButton key={index} {...button} />
          ))}
        </div>
      )}
      {isPatientNotSure && (
        <CheckoutPatientDecisionContainer maxWidth={false}>
          <div className="flex flex-col gap-6">
            <CheckoutNotGoingAheadOptions
              onChange={(event) => setNotSureReason(event.target.value as NotGoingAheadReason)}
              hasPrice={false}
              financeEnabled={false}
              displayPricing={false}
            />
            <div className="grid grid-cols-2 gap-4">
              <Button
                className="w-full"
                appearance="outline"
                mode="accent"
                size="medium"
                text="Cancel"
                onClick={() => setIsPatientNotSure(false)}
              />
              <Button
                disabled={!notSureReason}
                onClick={onClickConfirmNotSureReason}
                className="w-full"
                appearance="primary"
                mode="accent"
                size="medium"
                text="Confirm"
              />
            </div>
          </div>
        </CheckoutPatientDecisionContainer>
      )}
    </div>
  );
};
