import { useNavigate } from 'react-router-dom';
import { CopyText } from '@library/components/atoms';
import { Button, Label } from '@library/components/molecules';
import { t } from 'i18next';

import { Practice } from '@shared/data/types';
import { patientDashboardPage } from '@shared/utils/urls';

export const FinanceApprovalBlock = ({
  name,
  email,
  phone,
  address,
}: Pick<Practice, 'address' | 'name' | 'email' | 'phone'>) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col gap-3 justify-center text-center">
        <CopyText variant="heading" size="xSmall">
          {t('patient.confirmationPage.heading')}
        </CopyText>
        <CopyText size="large" colour="foregroundNeutralSecondary">
          {t('patient.confirmationPage.description')}
        </CopyText>
      </div>
      <div className="flex flex-col p-6 border border-borderNeutralDefault rounded-2xl mt-6">
        <div className="flex flex-col gap-3">
          <Label appearance="secondary" size="medium" labelText={name} helperText={address} labelStrong={false} />
          <Label appearance="secondary" size="medium" labelText="Phone" helperText={phone} labelStrong={false} />
          <Label appearance="secondary" size="medium" labelText="Email" helperText={email} labelStrong={false} />
        </div>
        <div className="grid grid-cols-2 gap-2 mt-6">
          <a className="block" href={`tel:${phone}`}>
            <Button className="w-full" size="medium" mode="accent" appearance="primary" leftIcon="phone" text="" />
          </a>
          <a className="block" href={`mailto:${email}`}>
            <Button className="w-full" size="medium" mode="accent" appearance="subtle" leftIcon="mail" text="" />
          </a>
        </div>
      </div>
      <div className="mt-6 text-center">
        <Button
          onClick={() => navigate(patientDashboardPage)}
          appearance="subtle"
          mode="accent"
          size="medium"
          text={t('patient.checkoutPage.goToMyGuide')}
        />
      </div>
    </>
  );
};
