import { captureMessage } from '@sentry/react';
import axios, { AxiosError, AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { DateTime } from 'luxon';

import { FetchError } from '@shared/data/types';
import { useAuth } from '@shared/stores/authStore';

import { handleMagcLinkErrors } from './handleReactQueryErrors';

const defaultConfig = {
  baseURL: import.meta.env.VITE_BOOST_BACKEND_URL,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
  },
};

const getTokenHeader = (token?: string): string => (token ? `Jwt token="${token}"` : '');
const isTokenExpired = (unixTimestamp: number) => {
  const timestampInMilliseconds = unixTimestamp * 1000;
  const expiryDateTime = DateTime.fromMillis(timestampInMilliseconds);
  const currentDateTime = DateTime.now();
  return expiryDateTime < currentDateTime;
};

// Practice user requests use a Bearer token for authentication that is provided by the backend
const getPracticeAxiosInstance = (): AxiosInstance => {
  const axiosInstance = axios.create(defaultConfig);

  axiosInstance.interceptors.request.use(async (config) => {
    const token = getTokenHeader(useAuth.getState().idToken);
    if (isTokenExpired(jwtDecode(token).exp!)) {
      await useAuth.getState().refreshSession();
      const newToken = getTokenHeader(useAuth.getState().idToken);
      if (config.headers) (config.headers as AxiosHeaders).set('Authorization', newToken);
    } else {
      if (config.headers) (config.headers as AxiosHeaders).set('Authorization', token);
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      let userType: 'practitioner' | 'patient' = 'patient';
      if (
        typeof error.config?.headers?.Authorization === 'string' &&
        error.config?.headers?.Authorization?.includes('Jwt token')
      ) {
        userType = 'practitioner';
      }
      captureMessage('API error', {
        level: 'debug',
        extra: {
          data: JSON.stringify(error.response?.data),
        },
        tags: {
          message: error.message,
          status: error.status,
          responseURL: error.request?.responseURL,
          method: error.request.method,
          userType,
        },
      });
      const responseError = (error.response?.data ? error.response?.data : error) as FetchError;
      if (
        (responseError.status === 401 && responseError.message === 'Token provided has expired.') ||
        responseError.message === 'Request failed with status code 401'
      ) {
        useAuth.getState().refreshSession();
      }

      throw {
        ...responseError,
        message: error.message || responseError.message,
      };
    },
  );

  return axiosInstance;
};

/**
 * Patient reuests use a custom Authorisation header that are a combination which follow the format `Magic secret="${secret}" device="${device}"`
 * The secret is contained in a query string parameter which can be found in a url sent out via email
 * The device id is a cryptographic generated and stored in localstorage when a user application first loads in a patient user's brower
 *  */
const getPatientAxiosInstance = (): AxiosInstance => {
  const axiosInstance = axios.create(defaultConfig);
  axiosInstance.interceptors.request.use(async (config) => {
    config.headers.set('Authorization', localStorage.getItem('authHeader'));
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      const responseError = (error.response?.data ? error.response?.data : error) as FetchError;
      handleMagcLinkErrors({
        errorMessage: (error.response?.data as { error: { code: string } })?.error?.code,
        status: responseError.status,
      });

      if (
        responseError.status !== 400 &&
        (responseError as unknown as { error: FetchError })?.error?.code !== 'NOT_ALLOWED'
      ) {
        throw {
          ...responseError,
          message: error.message || responseError.message,
        };
      }
    },
  );

  return axiosInstance;
};

export const practiceFetch = async (options: AxiosRequestConfig) => {
  const instance = getPracticeAxiosInstance();
  const response = await instance.request(options);
  return response.data;
};

export const patientFetch = async (options: AxiosRequestConfig) => {
  const instance = getPatientAxiosInstance();
  const response = await instance.request(options);
  return response.data;
};

export const unprotectedFetch = async (options: AxiosRequestConfig) => {
  const instance = axios.create(defaultConfig);
  const response = await instance.request(options);
  return response.data;
};
