import { useCallback, useState } from 'react';
import { TableHeader } from '@library/components/molecules';
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { PatientOverview, PatientTableView, UpdatePatientOverview } from '@shared/data/types';

import {
  applicantColumn,
  createdColumn,
  emailColumn,
  getActionColumn,
  loanColumn,
  netColumn,
  patientColumn,
  priceColumn,
  processedColumn,
  statusColumn,
  subsidyColumn,
  treatmentColumn,
  viewedColumn,
} from './tableColumns';

interface Props {
  patientOverview: PatientOverview[];
  practiceName: string;
  updatePatientOverview: UpdatePatientOverview;
  patientTableView?: PatientTableView;
}

export const useTable = ({ patientOverview, practiceName, updatePatientOverview, patientTableView }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filter, setFilter] = useState('');
  const [pageIndex, setPageIndex] = useState(0);

  const globalFilterFn = useCallback((row: Row<PatientOverview>, _columnId: string, filterValue: string) => {
    const firstName = (row.getValue('Patient') as string) || '';
    const email = (row.getValue('email') as string) || '';

    return filterValue
      ? firstName.toLowerCase().includes(filterValue.toLowerCase()) ||
          email.toLowerCase().includes(filterValue.toLowerCase())
      : true;
  }, []);

  const columns = getColumns(practiceName, updatePatientOverview, patientTableView);

  const table = useReactTable<PatientOverview>({
    data: patientOverview,
    enableColumnResizing: false,
    state: {
      sorting,
      globalFilter: filter,
      pagination: {
        pageSize: 7,
        pageIndex,
      },
      columnVisibility: {
        email: false,
      },
    },
    defaultColumn: {
      header: TableHeader,
    },
    globalFilterFn,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columns,
  });

  return {
    table,
    setFilter,
    setSorting,
    setPageIndex,
  };
};

const getColumns = (
  practiceName: string,
  updatePatientOverview: UpdatePatientOverview,
  patientTableView?: PatientTableView,
): ColumnDef<PatientOverview, unknown>[] => {
  const actionColumn = getActionColumn(practiceName, updatePatientOverview);

  if (patientTableView === PatientTableView.FINANCE) {
    // Finance only view columns
    return [
      patientColumn,
      applicantColumn, // Finance-specific column
      treatmentColumn,
      loanColumn,
      subsidyColumn,
      netColumn,
      processedColumn,
      statusColumn,
      actionColumn,
      emailColumn, // Hidden used for filtering
    ];
  } else {
    // Default view columns
    return [
      patientColumn,
      treatmentColumn,
      priceColumn,
      createdColumn,
      statusColumn,
      viewedColumn,
      actionColumn,
      emailColumn, // Hidden used for filtering
    ];
  }
};
