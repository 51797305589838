import { t } from 'i18next';
import { DateTime } from 'luxon';
import * as z from 'zod';

import { phoneNumberValidation, validations } from '@shared/utils/validations';

import { baseAddressSchema } from '../PostcodeInput/validations';

export const incomeTypes = [
  'Employed full-time',
  'Employed part-time',
  'Self employed',
  'Pension',
  'Other',
  'Director of a limited company',
  'Unemployed',
  'Retired, not working',
] as const;

export const titles = ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Dr', 'Prof', 'Rev'] as const;

export const ownershipStatuses = ['Yes, outright owner', 'Yes, with mortgage', 'No, renting', 'No, other'] as const;

export type QuotationApplicantionSchema = z.infer<typeof quotationApplicantionSchema>;

export const isEighteenYears = (
  /** // format 'yyyy-MM-dd' */
  dateString: string,
) => {
  const date = DateTime.fromFormat(dateString, 'yyyy-MM-dd');
  const now = DateTime.now();
  const eighteenYearsAgo = now.minus({ years: 18 });
  return date <= eighteenYearsAgo;
};

export const checkoutAddressSchema = baseAddressSchema.extend({
  moveInDate: z
    .string()
    .refine(
      (moveInDate) => {
        if (moveInDate) {
          const date = DateTime.fromFormat(moveInDate, 'yyyy-MM-dd');
          return date.isValid;
        }
        return false;
      },
      { message: t('common.validations.invalidDateError') },
    )
    .refine(
      (moveInDate) => {
        if (moveInDate) {
          const now = DateTime.now();
          const date = DateTime.fromFormat(moveInDate, 'yyyy-MM-dd');
          return !(date > now);
        }
        return false;
      },
      { message: t('common.validations.futureDateError') },
    ),
});

export const quotationApplicantionSchema = z
  .object({
    title: z.enum(titles, {
      message: t('common.validations.title'),
    }),
    firstName: z
      .string({ message: t('common.validations.firstName') })
      .trim()
      .min(2, {
        message: t('common.validations.firstName'),
      }),
    lastName: z
      .string({ message: t('common.validations.lastName') })
      .trim()
      .min(2, {
        message: t('common.validations.lastName'),
      }),
    email: validations.email({
      required: t('common.validations.emailInvalid'),
      invalid: t('common.validations.emailInvalid'),
    }),
    dateOfBirth: z
      .string({ message: t('common.validations.dateOfBirth') })
      .refine(
        (dateOfBirth) => {
          if (dateOfBirth) {
            const date = DateTime.fromFormat(dateOfBirth, 'yyyy-MM-dd');
            return date.isValid;
          }
          return false;
        },
        { message: t('common.validations.invalidDateError') },
      )
      .refine(isEighteenYears, {
        message: t('common.validations.over18'),
      }),
    mobileNumber: phoneNumberValidation(false, t('common.validations.phone')),
    instalmentDate: z.number({ message: t('common.validations.preferedDate') }),
    incomeType: z.enum(incomeTypes, {
      message: t('common.validations.employment'),
    }),
    annualSalary: z.string({ message: t('common.validations.income') }),
    rent: z.string().optional(),
    residentialStatus: z.enum(ownershipStatuses, {
      message: t('common.validations.residentialStatus'),
    }),
    addresses: z.array(checkoutAddressSchema).refine(
      (addresses) => {
        if (addresses.length > 0) {
          const addressOrderInvalid = addresses.every(({ moveInDate }, index) => {
            if (index === 0) return true;
            const recentAddress = DateTime.fromFormat(addresses[index - 1].moveInDate, 'yyyy-MM-dd');
            const previousAddress = DateTime.fromFormat(moveInDate, 'yyyy-MM-dd');
            return !(previousAddress > recentAddress);
          });
          return addressOrderInvalid;
        }
        return false;
      },
      { message: t('common.validations.addressOrderInvalid') },
    ),
    dobDay: z.string().optional(),
    dobMonth: z.string().optional(),
    dobYear: z.string().optional(),
  })
  .refine(
    ({ rent, residentialStatus }) =>
      residentialStatus === 'No, renting' || residentialStatus === 'Yes, with mortgage' ? rent : true,
    {
      message: t('common.validations.housingCosts'),
      path: ['rent'],
    },
  );
