import { CSSProperties, memo } from 'react';
import clsx from 'clsx';
type SpinnerPositions = {
  centered?: boolean;
  inButton?: boolean;
  colour?: string;
};

export const SimpleSpinner = ({ colour }: { colour?: string }) => (
  <div data-testid="spinner" className={clsx('lds-ellipsis')} aria-label="loading...">
    <div className={colour}></div>
    <div className={colour}></div>
    <div className={colour}></div>
    <div className={colour}></div>
  </div>
);

export const Spinner = memo(({ centered = true, inButton, colour }: SpinnerPositions) => {
  const props = {
    ...(centered && { style: { position: 'absolute', left: '50%', top: '50%' } }),
    ...(inButton && { style: { margin: '0 1rem' } }),
  } as { style: CSSProperties };

  return centered || inButton ? (
    <div {...props}>
      <div className="relative right-[50%]">
        <SimpleSpinner colour={colour} />
      </div>
    </div>
  ) : (
    <SimpleSpinner colour={colour} />
  );
});

Spinner.displayName = 'Spinner';
