import { useNavigate } from 'react-router-dom';
import { CopyText, Illustration } from '@library/components/atoms';
import { Button } from '@library/components/molecules';
import Quote from '@library/components/molecules/Quote';
import { t } from 'i18next';

import { DivideBuyInstalment, Quote as QuoteI, QuoteDecision } from '@shared/data/types';
import { patientDashboardPage } from '@shared/utils/urls';

import { Empty } from '../Empty';

interface QuotesProps {
  requestedQuote?: QuoteI;
  alternativeQuotes?: QuoteI[];
  instalmentsData: DivideBuyInstalment[];
  onContinueToDividebuy: (quoteId: string) => void;
  postSelectQuoteIsLoading: boolean;
}

export const DividebuyEligibilityResults = ({
  requestedQuote,
  alternativeQuotes,
  instalmentsData,
  onContinueToDividebuy,
  postSelectQuoteIsLoading,
}: QuotesProps) => {
  const navigate = useNavigate();

  // If requested quote declined and no alternatives display declined message
  if (requestedQuote?.decision === QuoteDecision.DECLINED && !alternativeQuotes?.length) {
    return (
      <div className="flex flex-col gap-6 items-center">
        <Illustration size="small" type="confused1" />
        <CopyText variant="heading" size="xSmall" className="text-center">
          {t('patient.dividebuyLoanApplicationPage.eligibilityResults.notEligibleTitle')}
        </CopyText>
        <CopyText size="large" className="!text-foregroundNeutralSecondary text-center">
          {t('patient.dividebuyLoanApplicationPage.eligibilityResults.notEligibleDescription')}
        </CopyText>
        <Button
          onClick={() => navigate(patientDashboardPage)}
          size="large"
          appearance="subtle"
          mode="accent"
          text={t('patient.checkoutPage.goToMyGuide')}
        />
      </div>
    );
  }

  // If request quote approved display requested quote
  if (requestedQuote?.decision === QuoteDecision.APPROVED && requestedQuote.checkout_url) {
    /** The interest rate is not currently returned in the quotation API response so we filter the results of available instalments */
    const interestRate = instalmentsData.find(
      (instalment) => instalment.term_length === requestedQuote.details?.total_instalments,
    )?.interest_rate;
    return (
      <div className="flex flex-col gap-6">
        <CopyText variant="heading" size="xSmall">
          {t('patient.dividebuyLoanApplicationPage.eligibilityResults.selectedOptionTitle')}
        </CopyText>
        <Quote
          details={requestedQuote.details}
          interestRate={interestRate}
          onContinueToDividebuy={onContinueToDividebuy}
          quoteId={requestedQuote.id}
          postSelectQuoteIsLoading={postSelectQuoteIsLoading}
        />
        <CantProceed />
      </div>
    );
  }

  // If alternative quotes approved display alternatives
  if (alternativeQuotes?.length) {
    return (
      <div className="flex flex-col gap-6">
        <CopyText variant="heading" size="xSmall">
          {t('patient.dividebuyLoanApplicationPage.eligibilityResults.alternativeOptionsTitle')}
        </CopyText>
        <CopyText size="large" colour="foregroundNeutralSecondary">
          {t('patient.dividebuyLoanApplicationPage.eligibilityResults.alternativeOptionsDescription')}
        </CopyText>
        {alternativeQuotes.map((alternativeQuote) => {
          /** The interest rate is not currently returned in the quotation API response so we filter the results of available instalments */
          const interestRate = instalmentsData.find(
            (instalment) => instalment.term_length === alternativeQuote.details?.total_instalments,
          )?.interest_rate;
          return (
            <Quote
              key={alternativeQuote.id}
              details={alternativeQuote.details}
              interestRate={interestRate}
              onContinueToDividebuy={onContinueToDividebuy}
              quoteId={alternativeQuote.id}
              postSelectQuoteIsLoading={postSelectQuoteIsLoading}
            />
          );
        })}
        <CantProceed />
      </div>
    );
  }

  return (
    <div className="mb-6">
      <Empty
        illustration="confused1"
        size="large"
        title={t('plans.error.title')}
        description={t('patient.dividebuyLoanApplicationPage.eligibilityResults.errorMessage')}
      />
    </div>
  );
};

const CantProceed = () => (
  <div className="flex flex-col gap-4 border border-foregroundNeutralDefault border-solid rounded-2xl p-5">
    <Illustration size="small" type="onThePhone" />
    <div className="flex flex-col gap-1">
      <CopyText variant="heading" size="xxxSmall">
        {t('patient.dividebuyLoanApplicationPage.eligibilityResults.cannotProceedTitle')}
        <br />
        {t('patient.dividebuyLoanApplicationPage.eligibilityResults.cannotProceedNoWorries')}
      </CopyText>
      <CopyText colour="foregroundNeutralSecondary">
        {t('patient.dividebuyLoanApplicationPage.eligibilityResults.cannotProceedDescription')}
      </CopyText>
    </div>
  </div>
);
