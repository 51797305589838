import { memo } from 'react';
import { Icon } from '@library/components/atoms';
import { IconName } from '@library/components/atoms/Icon/utils';
import { getTextSize } from '@library/utils';

interface ErrorTextProps {
  icon: IconName;
  text: string;
}

export const ErrorText = memo(({ icon, text }: ErrorTextProps) => {
  return (
    <div className="flex gap-2 text-foregroundErrorPrimary error-message">
      <Icon iconName={icon} color="currentColor" size="xSmall" />
      <span className={getTextSize('small')}>{text}</span>
    </div>
  );
});

ErrorText.displayName = 'ErrorText';
