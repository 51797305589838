import { useEffect, useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import LoggedInNav from '@patient/components/LoggedInNav';
import { Footer } from '@patient/templates/Footer/Footer';
import { Spinner } from '@shared/components/Spinner';
import { useGetPractice, useGetTreatmentGuide } from '@shared/data/practice/hooks';
import { Accessor } from '@shared/data/types';
import { IntercomService } from '@shared/services/Intercom';

export const AuthWrapper = () => {
  const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
  const practiceId = treatmentGuideId.split(':')[0];
  const intercomService = useMemo(() => new IntercomService(), []);

  const {
    data: treatmentGuide,
    isInitialLoading: treatmentGuideIsLoading,
    refetch: refetchTreatmentGuide,
  } = useGetTreatmentGuide(treatmentGuideId, Accessor.PATIENT, undefined, false);

  const {
    data: practice,
    isInitialLoading: practiceIsLoading,
    refetch: refetchPractice,
  } = useGetPractice(practiceId, Accessor.PATIENT, { enabled: false });

  const treatmentGuideData = treatmentGuide?.data;

  useEffect(() => {
    if (localStorage.getItem('authHeader')) {
      refetchTreatmentGuide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (practiceId) {
      refetchPractice();
    }
  }, [practiceId, refetchPractice]);

  useEffect(() => {
    if (treatmentGuideData) {
      intercomService.bootIntercom(
        treatmentGuideData.firstName!,
        treatmentGuideData.lastName!,
        treatmentGuideData.email!,
        'Patient',
        practice?.data.name,
      );
    }
  }, [treatmentGuideData, practice, intercomService]);

  if (!treatmentGuideId) return <Navigate to="/patient/login" />;

  if (practiceIsLoading || treatmentGuideIsLoading) return <Spinner centered />;

  if (treatmentGuide) {
    return (
      <div>
        {treatmentGuide && (
          <LoggedInNav
            patientName={`${treatmentGuide.data.firstName} ${treatmentGuide.data.lastName}`}
            logoUrl={practice?.data?.logoUrl || ''}
          />
        )}
        <main className="xl:pt-0">{<Outlet />}</main>
        {practice && <Footer practiceName={practice?.data.name} />}
      </div>
    );
  }
  return null;
};
