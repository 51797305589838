import { Option } from '@library/components/molecules';
import { Size } from '@library/types';
import { captureMessage } from '@sentry/react';

import { PatientOverview } from '@shared/data/types';

export const getTextSize = (size: Size) => {
  switch (size) {
    case 'xSmall':
      return 'text-[0.75rem] leading-5 tracking-[0.015rem]';
    case 'small':
      return 'text-[0.875rem] leading-5 tracking-[0.02rem]';
    case 'medium':
      return 'text-[1rem] leading-6';
    case 'large':
      return 'text-[1.125rem] leading-7';
    case 'xLarge':
      return 'text-[1.25rem] leading-8';
  }
};

export const getPaddedSize = (size: Size) => {
  switch (size) {
    case 'xSmall':
      return 'text-sm h-8 p-4';
    case 'small':
      return 'text-sm h-10 p-4';
    case 'medium':
      return 'text-base h-12 p-5';
    case 'large':
      return 'text-base h-14 p-5';
    case 'xLarge':
      return 'text-large h-16 p-5';
  }
};

export const transitionClasses = ' transition-colors duration-200 ease-out';

/** assumes the value is in pence and will convert it into pounds */
export const convertToCurrency = (total: number, currency: string, inPounds?: boolean) => {
  if (inPounds) {
    return `${currency}${total.toFixed(2)}`;
  }
  return `${currency}${(total / 100).toFixed(2)}`;
};

export const titleOptions: Option[] = [
  {
    name: 'Mr',
    value: 'Mr',
  },
  {
    name: 'Mrs',
    value: 'Mrs',
  },
  {
    name: 'Miss',
    value: 'Miss',
  },
  {
    name: 'Ms',
    value: 'Ms',
  },
  {
    name: 'Mx',
    value: 'Mx',
  },
  {
    name: 'Dr',
    value: 'Dr',
  },
  {
    name: 'Prof',
    value: 'Prof',
  },
  {
    name: 'Rev',
    value: 'Rev',
  },
];

export const scrollToTop = (behavior: ScrollBehavior = 'smooth') => {
  if (typeof window !== 'undefined' && window.scrollTo) {
    window.scrollTo({ top: 0, left: 0, behavior });
  }
};

export const scrollToFirstErrorMessage = () => {
  const firstErrorMessage = document.querySelector('.error-message');

  if (firstErrorMessage) {
    const elementRect = firstErrorMessage.getBoundingClientRect();

    const offset = 90;
    const scrollPosition = window.scrollY + elementRect.top - offset;

    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  }
};

export function updatePatientOverviewList(
  patientOverview: PatientOverview[],
  rowData: PatientOverview[],
  treatmentGuideId: string,
): PatientOverview[] {
  const updatedPatientOverview = patientOverview.find((overview) => overview.treatmentGuideId === treatmentGuideId);

  if (updatedPatientOverview) {
    let updatedList = rowData;
    updatedList = rowData.filter((patient) => patient.treatmentGuideId !== treatmentGuideId);
    updatedList.unshift(updatedPatientOverview);
    return updatedList;
  } else {
    captureMessage('Failed to update patient overview list', {
      level: 'debug',
      extra: {
        treatmentGuideId,
      },
    });
    return rowData;
  }
}

export const formatSortCode = (sortcode: string) => {
  //sortcode should be in format 00-00-00
  let formattedCode = sortcode.replace(/\D/g, '');
  formattedCode = formattedCode.match(/.{1,2}/g)?.join('-') ?? formattedCode;
  formattedCode = formattedCode.substring(0, 8);
  return formattedCode;
};
