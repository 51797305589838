import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Icon, Illustration } from '@library/components/atoms';
import { CopyText } from '@library/components/atoms/CopyText';
import PageContentWrapper from '@library/components/atoms/PageContentWrapper';
import { Button } from '@library/components/molecules';
import { FinanceApprovalBlock } from '@library/components/organisms/FinanceApprovalBlock/FinanceApprovalBlock';
import { t } from 'i18next';

import { Spinner } from '@shared/components/Spinner';
import { useGetPractice, useGetTreatmentGuide } from '@shared/data/practice/hooks';
import { Accessor, IPaymentMethod } from '@shared/data/types';
import { patientDashboardPage } from '@shared/utils/urls';

export const FinanceWelcomeBackPage = () => {
  const navigate = useNavigate();
  const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
  const practiceId = treatmentGuideId.split(':')[0];

  const { data: practice, isLoading: practiceIsLoading } = useGetPractice(practiceId, Accessor.PATIENT);
  const { data: treatmentGuide, isLoading: treatmentGuideIsLoading } = useGetTreatmentGuide(
    treatmentGuideId,
    Accessor.PATIENT,
  );

  if (practiceIsLoading || treatmentGuideIsLoading) {
    return <Spinner centered />;
  }

  if (!practice?.data) {
    return null;
  }

  if (
    treatmentGuide?.data.paymentMethod === IPaymentMethod.FINANCE &&
    !localStorage.getItem(`has-seen-confirmation-${treatmentGuideId}`)
  ) {
    localStorage.setItem(`has-seen-confirmation-${treatmentGuideId}`, 'true');
  }

  const financeSuccess = treatmentGuide?.data.paymentMethod === IPaymentMethod.FINANCE;

  return (
    <PageContentWrapper>
      {financeSuccess ? (
        <FinanceApprovalBlock
          name={practice.data.name}
          email={practice.data.email}
          address={practice.data.address}
          phone={practice.data.phone}
        />
      ) : (
        <div className="flex flex-col items-center">
          <div className="mb-6">
            <Illustration size="small" type="confused1" />
          </div>
          <div className="flex flex-col gap-3 justify-center text-center">
            <CopyText variant="heading" size="xSmall">
              {t('patient.checkoutWelcomeBackPage.notFinalisedHeading')}
            </CopyText>
            <CopyText size="large" colour="foregroundNeutralSecondary">
              {t('patient.checkoutWelcomeBackPage.notFinalisedDescription')}
            </CopyText>
          </div>
          <div className="mt-6 text-center">
            <Button
              onClick={() => navigate(patientDashboardPage)}
              appearance="subtle"
              mode="accent"
              size="medium"
              text={t('patient.checkoutPage.goToMyGuide')}
            />
          </div>
          <div className="flex gap-2 items-start mt-[12.5rem] p-5 bg-backgroundNeutralSoft rounded-xl">
            <Icon className="p-[0.0625rem]" size="small" iconName="info" color="text-foregroundNeutralPrimary" />
            <CopyText size="small" colour="foregroundNeutralPrimary">
              <Trans
                i18nKey="patient.checkoutWelcomeBackPage.contactDividebuy"
                components={{
                  contactLink: (
                    <a
                      target="_blank"
                      href="https://dividebuy.co.uk/help/"
                      className="underline text-foregroundBrandPrimary"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </CopyText>
          </div>
        </div>
      )}
    </PageContentWrapper>
  );
};
