export const inputClasses = `
  border-0
  !ring-inset
  !ring-borderNeutralDefault !ring-1
  hover:!ring-borderBrandDefault hover:!ring-2
  focus:!ring-borderBrandDefault focus:!ring-[3px]
  bg-white
  text-foregroundNeutralPrimary
  rounded-lg
  inline-block
  w-full
  placeholder:text-foregroundNeutralTertiary
  hover:placeholder:text-foregroundNeutralTertiary
  focus:placeholder:text-foregroundNeutralTertiary
`;

export const inputDisabledClasses = `
  placeholder:!text-foregroundNeutralQuaternary
  disabled:bg-backgroundNeutralSoft
  disabled:ring-borderNeutralDefault
  disabled:ring-1
  disabled:!text-foregroundNeutralQuaternary
`;

export const errorClasses = `
  !text-foregroundErrorPrimary
  !ring-[3px]
  placeholder:text-foregroundErrorPrimary
  hover:placeholder:text-foregroundErrorPrimary
  focus:placeholder:text-foregroundErrorPrimary
  hover:ring-foregroundErrorPrimary
  focus:ring-foregroundErrorPrimary
  !ring-foregroundErrorPrimary
`;
