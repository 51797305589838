import { CopySize } from '@library/components/atoms/CopyText';
import { TextStyles } from '@library/types';

export const getDisplayTextStyles = (size: CopySize) => {
  switch (size) {
    case 'medium':
      return 'font-medium text-[3rem] leading-[3.5rem] tracking-[-0.01em]';
    case 'large':
      return 'font-medium text-[4rem] leading-[4.25rem] tracking-[-0.01em]';
    case 'xLarge':
      return 'font-medium text-[7rem] leading-[6.375rem] tracking-[-0.01em]';
  }
};

export const getHeadingStyles = (size: CopySize) => {
  switch (size) {
    case 'xxxSmall':
      return 'font-medium text-[1.25rem] leading-[1.75rem] tracking-[0rem]';
    case 'xxSmall':
      return 'font-medium text-[1.5rem] leading-[2rem] tracking-[0rem]';
    case 'xSmall':
      return 'font-medium text-[1.75rem] leading-[2.25rem] tracking-[0rem]';
    case 'small':
      return 'font-medium text-[2rem] leading-[2.5rem] tracking-[0rem]';
    case 'medium':
      return 'font-medium text-[2.25rem] leading-[2.75rem] tracking-[0rem]';
    case 'large':
      return 'font-medium text-[2.5rem] leading-[3rem] tracking-[0rem]';
  }
};

export const getParagraphStyles = (size: CopySize) => {
  switch (size) {
    case 'caption':
      return 'font-normal text-[0.75rem] leading-[1.25rem] tracking-[0.02em]';
    case 'small':
      return 'font-normal text-[0.875rem] leading-[1.25rem] tracking-[0.02em]';
    case 'medium':
      return 'font-normal text-[1rem] leading-[1.5rem] tracking-[0.02rem]';
    case 'large':
      return 'font-normal text-[1.125rem] leading-[1.75rem] tracking-[0.01em]';
    case 'xLarge':
      return 'font-normal text-[1.25rem] leading-[2rem] tracking-[0.01em]';
  }
};

export const getLabelStyles = (size: CopySize) => {
  switch (size) {
    case 'caption':
      return 'font-medium text-[0.75rem] leading-[1.25rem] tracking-[0.02em]';
    case 'small':
      return 'font-medium text-[0.875rem] leading-[1.25rem] tracking-[0.02em]';
    case 'medium':
      return 'font-medium text-[1rem] leading-[1.5rem] tracking-[0.01em]';
    case 'large':
      return 'font-medium text-[1.125rem] leading-[1.75rem] tracking-[0.01em]';
    case 'xLarge':
      return 'font-medium text-[1.25rem] leading-[2rem] tracking-[0.01em]';
  }
};

export const getTextStyle = (size: CopySize, textStyle: TextStyles) => {
  switch (textStyle) {
    case 'display':
      return getDisplayTextStyles(size);
    case 'heading':
      return getHeadingStyles(size);
    case 'paragraph':
      return getParagraphStyles(size);
    case 'label':
      return getLabelStyles(size);
  }
};
