import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';
import { Checkbox, Input, Option, Selector } from '@library/components/molecules';
import { dateFormatRegex, DateOfBirthField } from '@library/components/molecules/DateOfBirthField/DateOfBirthField';
import clsx from 'clsx';

import { LoadingIcon } from '../LoadingIcon';

export type TextInputProps = {
  name: string;
  placeholder: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formProps: UseFormReturn<FieldValues>;
  labelText?: string;
  id: string;
  type?: 'text' | 'email' | 'tel';
  preventNumericValues?: boolean;
  onlyNumericValues?: boolean;
  isLoading?: boolean;
  testId?: string;
};

export const TextInput = ({
  formProps,
  name,
  placeholder,
  onBlur,
  onChange,
  labelText,
  id,
  type = 'text',
  preventNumericValues,
  onlyNumericValues,
  isLoading,
  testId,
}: TextInputProps) => {
  return (
    <Controller
      name={name}
      control={formProps.control}
      render={({ field, fieldState }) => (
        <div className="w-full relative">
          <Input
            testId={testId || id}
            inputSize="medium"
            type={type}
            id={id}
            labelText={labelText}
            labelSize="small"
            placeholder={placeholder}
            preventNumericValues={preventNumericValues}
            onlyNumericValues={onlyNumericValues}
            errorText={fieldState.error ? fieldState.error.message : ''}
            {...field}
            onBlur={onBlur}
            onChange={(event) => {
              field.onChange(event);
              onChange && onChange(event);
            }}
          />
          {isLoading && (
            <div className="absolute right-3 top-[2.85rem]" data-testid="loadingIcon">
              <LoadingIcon size="small" />
            </div>
          )}
        </div>
      )}
    />
  );
};

export type SelectorInputProps = {
  formProps: UseFormReturn<FieldValues>;
  options: Option[];
  name: string;
  placeholder?: string;
  labelText?: string;
  testId?: string;
  defaultSelectedIndex?: number;
};
export const SelectorInput = ({
  formProps,
  options,
  name,
  placeholder,
  labelText,
  testId,
  defaultSelectedIndex,
}: SelectorInputProps) => {
  return (
    <Controller
      name={name}
      control={formProps?.control}
      render={({ field, fieldState }) => (
        <Selector
          size="medium"
          menuSize="medium"
          options={options}
          testId={testId}
          labelText={labelText}
          placeholder={placeholder}
          validationError={fieldState.error?.message}
          {...field}
          className={clsx('w-full max-w-full', field.value?.value && '!text-black')}
          onChange={(event) => {
            field.onChange(event);
            formProps?.trigger(name);
          }}
          defaultSelectedIndex={defaultSelectedIndex}
        />
      )}
    />
  );
};

export type CheckboxInputProps = {
  formProps?: UseFormReturn<FieldValues>;
  name: string;
  id: string;
  labelChildren?: React.ReactNode;
  labelText?: string;
};

export const CheckboxInput = ({ formProps, name, labelChildren, labelText, id }: CheckboxInputProps) => {
  return (
    <Controller
      name={name}
      control={formProps?.control}
      render={({ field, fieldState }) => (
        <Checkbox
          testId={id}
          id={id}
          {...field}
          checked={field.value}
          errorText={fieldState.error?.message}
          checkboxSize="medium"
          labelTextClassName="text-sm cursor-pointer"
          onBlur={() => {
            formProps?.trigger(name);
          }}
          onInput={() => {
            formProps?.trigger(name);
          }}
          labelChildren={labelChildren}
          labelText={labelText}
        />
      )}
    />
  );
};

export type DateOfBirthInputProps = {
  formProps: UseFormReturn<FieldValues>;
  testIdPrefix?: string;
};

export const DateOfBirthInput = ({ formProps, testIdPrefix }: DateOfBirthInputProps) => {
  return (
    <Controller
      key="dateOfBirth"
      name="dateOfBirth"
      control={formProps.control}
      render={({ fieldState }) => {
        return (
          <DateOfBirthField
            labelText="Date of birth"
            onChange={(value) => {
              formProps.setValue('dateOfBirth', value);
              if (dateFormatRegex.test(value)) {
                formProps.trigger('dateOfBirth');
              }
            }}
            errorText={fieldState?.error?.message}
            testIdPrefix={testIdPrefix}
            initialValue={formProps.getValues('dateOfBirth')}
          />
        );
      }}
    />
  );
};
type InputRowProps = {
  children: React.ReactNode;
  isDesktop?: boolean;
};

export const StyledInputRow = ({ children, isDesktop }: InputRowProps) => {
  return <div className={clsx('flex justify-between w-full gap-4', isDesktop || 'flex-col')}>{children}</div>;
};
