import { Dispatch, SetStateAction } from 'react';
import { TableCell } from '@library/components/atoms/TableCell';
import { TableHeader } from '@library/components/molecules';
import { CellContext, ColumnDef, getCoreRowModel, SortingState, useReactTable } from '@tanstack/react-table';

import { formatCreatedAtValue } from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/utils';
import PatientRenderer from '@practice/pages/PatientsPages/PatientsDashboard/SubscriptionTable/CellRenderers/PatientRenderer';
import { PlanRenderer } from '@practice/pages/PatientsPages/PatientsDashboard/SubscriptionTable/CellRenderers/PlanRenderer/PlanRenderer';
import StatusRenderer from '@practice/pages/PatientsPages/PatientsDashboard/SubscriptionTable/CellRenderers/StatusRenderer';
import { SubscriptionOverview } from '@shared/data/types';
import { currencyFormatter } from '@shared/utils/numberFormatter';

type CellFn<T> = CellContext<SubscriptionOverview, T>;

interface Props {
  subscriptionOverview: SubscriptionOverview[];
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
}

export const useTable = ({ subscriptionOverview, sorting, setSorting }: Props) => {
  const columns: ColumnDef<SubscriptionOverview>[] = [
    {
      id: 'Name',
      accessorFn: (data: SubscriptionOverview) => {
        return data.patientName;
      },
      cell: PatientRenderer,
      size: 300,
    },
    {
      id: 'Date of birth',
      accessorFn: (data: SubscriptionOverview) => data.patientDateOfBirth,
      cell: (data: CellFn<string>) => <TableCell>{formatCreatedAtValue(data.getValue())}</TableCell>,
      size: 120,
    },
    {
      id: 'Plan',
      accessorFn: (data: SubscriptionOverview) => data.planName,
      cell: PlanRenderer,
      size: 200,
    },
    {
      id: 'Monthly fee',
      accessorFn: (data: SubscriptionOverview) => parseInt(data.subscriptionPrice),
      cell: (data: CellFn<number>) => <TableCell>{currencyFormatter(data.getValue())}</TableCell>,
      size: 120,
    },
    {
      id: 'Start date',
      accessorFn: (data: SubscriptionOverview) => data.subscriptionStartDate,
      cell: (data: CellFn<string>) => <TableCell>{formatCreatedAtValue(data.getValue())}</TableCell>,
      size: 120,
    },
    {
      id: 'Status',
      accessorFn: (data: SubscriptionOverview) => data.subscriptionStatus,
      cell: StatusRenderer,
      size: 156,
    },
  ];

  const table = useReactTable({
    data: subscriptionOverview,
    manualSorting: true,
    manualPagination: true,
    rowCount: subscriptionOverview.length,
    state: {
      sorting,
    },
    defaultColumn: {
      header: TableHeader,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    columns,
  });

  return {
    table,
  };
};
