import { TableCell } from '@library/components/atoms/TableCell';
import { BaseLabel, TableHeader, Tooltip } from '@library/components/molecules';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import clsx from 'clsx';
import { t } from 'i18next';

import {
  ActionRenderer,
  PatientRendererNewDS,
} from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/CellRenderers';
import { DecisionRendererNewDS } from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/CellRenderers/DecisionRenderer/DecisionRenderer';
import { TreatmentRendererNewDS } from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/CellRenderers/TreatmentRenderer/TreatmentRenderer';
import {
  formatCreatedAtValue,
  formatProcessedAtValue,
  getDecisionFieldValue,
  getPriceFieldValue,
  getTreatmentFieldValue,
  showLastViewedDate,
  showPriceFieldValue,
} from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/utils';
import { PatientOverview, PricingType, UpdatePatientOverview } from '@shared/data/types';

// type CellFn<T> = ColumnDefTemplate<CellContext<PatientOverview, T>>;

// Base Columns
export const patientColumn: ColumnDef<PatientOverview, unknown> = {
  id: 'Patient',
  accessorFn: (data: PatientOverview) => `${data.firstName} ${data.lastName}`,
  cell: PatientRendererNewDS,
  size: 180,
};

export const treatmentColumn: ColumnDef<PatientOverview, unknown> = {
  id: 'Treatment',
  accessorFn: (data: PatientOverview) => getTreatmentFieldValue(data?.treatments),
  cell: TreatmentRendererNewDS,
  size: 350,
};

export const statusColumn: ColumnDef<PatientOverview, unknown> = {
  accessorKey: 'patientAction',
  id: 'Status',
  accessorFn: ({ patientAction }) => getDecisionFieldValue(patientAction),
  cell: DecisionRendererNewDS,
  size: 140,
};

export const getActionColumn: (
  practiceName: string,
  updatePatientOverview: UpdatePatientOverview,
) => ColumnDef<PatientOverview, unknown> = (practiceName, updatePatientOverview) => ({
  id: ' ',
  accessorKey: '',
  size: 56,
  cell: (props: CellContext<PatientOverview, unknown>) => (
    <TableCell className={clsx('!px-0')}>
      <ActionRenderer
        practiceName={practiceName}
        data={props.row.original}
        updatePatientOverview={updatePatientOverview}
      />
    </TableCell>
  ),
});

export const emailColumn: ColumnDef<PatientOverview> = {
  accessorKey: 'email',
  enableHiding: true,
};

// Finance-Specific Columns
export const applicantColumn: ColumnDef<PatientOverview, unknown> = {
  id: 'Applicant',
  accessorKey: 'applicantName',
  cell: (data) => <TableCell>{data.getValue() as string}</TableCell>,
  size: 100,
};

export const loanColumn: ColumnDef<PatientOverview, unknown> = {
  id: 'Loan',
  accessorKey: 'loanAmount',
  header: (props) => <TableHeader {...props} tooltipText={t('practice.patientDashboard.table.loanTooltip')} />,
  cell: (data) => <TableCell>{showPriceFieldValue(data.getValue() as number)}</TableCell>,
  size: 100,
};

export const subsidyColumn: ColumnDef<PatientOverview, unknown> = {
  id: 'Subsidy',
  accessorKey: 'subsidyAmount',
  header: (props) => <TableHeader {...props} tooltipText={t('practice.patientDashboard.table.subsidyTooltip')} />,
  cell: (data) => <TableCell>{showPriceFieldValue(data.getValue() as number)}</TableCell>,
  size: 100,
};

export const netColumn: ColumnDef<PatientOverview, unknown> = {
  id: 'Net',
  accessorKey: 'remittanceAmount',
  header: (props) => <TableHeader {...props} tooltipText={t('practice.patientDashboard.table.netTooltip')} />,
  cell: (data) => <TableCell>{showPriceFieldValue(data.getValue() as number)}</TableCell>,
  size: 100,
};

export const processedColumn: ColumnDef<PatientOverview, unknown> = {
  id: 'Processed',
  accessorKey: 'fulfilledAt',
  header: (props) => <TableHeader {...props} tooltipText={t('practice.patientDashboard.table.processedTooltip')} />,
  cell: (props) => <TableCell>{formatProcessedAtValue(props.getValue() as string | null)}</TableCell>,
  size: 100,
};

// Non-Finance-Specific Columns
export const priceColumn: ColumnDef<PatientOverview, unknown> = {
  id: 'Price',
  size: 100,
  accessorFn: (data: PatientOverview) => {
    return getPriceFieldValue(data?.price, data?.pricingType, data?.displayPricing);
  },
  cell: (data) => (
    <TableCell>
      {data?.row.original?.deposit ? (
        <span>
          <Tooltip
            position="top"
            title={`In-practice deposit: ${showPriceFieldValue(parseFloat(data?.row.original?.deposit))}`}
            arrow
          >
            <BaseLabel
              labelTextClassName="border-b border-dashed border-borderNeutralDefault"
              badgeAppearance="none"
              badgePosition="col"
              badgeText={
                data.row.original?.displayPricing && data.row.original?.pricingType === PricingType.ESTIMATED
                  ? 'Estimated'
                  : undefined
              }
              size="small"
              text={showPriceFieldValue(data.getValue() as number)}
            />
          </Tooltip>
        </span>
      ) : (
        <BaseLabel
          badgeAppearance="none"
          badgePosition="col"
          badgeText={
            data.row.original?.displayPricing && data.row.original?.pricingType === PricingType.ESTIMATED
              ? 'Estimated'
              : undefined
          }
          size="small"
          text={showPriceFieldValue(data.getValue() as number)}
        />
      )}
    </TableCell>
  ),
};

export const createdColumn: ColumnDef<PatientOverview, unknown> = {
  id: 'Created',
  accessorKey: 'createdAt',
  cell: (data) => <TableCell>{formatCreatedAtValue(data.getValue() as string)}</TableCell>,
  size: 100,
};

export const viewedColumn: ColumnDef<PatientOverview, unknown | null> = {
  id: 'Viewed',
  accessorFn: ({ lastViewedAt }) => lastViewedAt || null,
  accessorKey: 'lastViewedAt',
  cell: (props) => <TableCell>{showLastViewedDate(props.getValue() as string)}</TableCell>,
  size: 100,
};
