import { t } from 'i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as z from 'zod';

type ValidationMessages = {
  required: string;
  invalid: string;
};

const sortCodeRegex = /^[0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/;

export const phoneNumberValidation = (optional: boolean, required?: string) =>
  z
    .string({ message: required || t('common.validations.phone') })
    .refine((value) => (optional && value === '') || (value && /^[\d\s]+$/.test(value)), {
      message: required || t('common.validations.phone'),
    })
    .transform((value) => value.replace(/\s+/g, '')) // Remove all spaces
    .refine((value) => (optional && value === '') || (value && value.length === 11), {
      message: required || t('common.validations.phone'),
    })
    .refine((value) => (optional && value === '') || (value && isValidPhoneNumber(value, 'GB')), {
      message: required || t('common.validations.phone'),
    });

export const validations = {
  email: (
    { required, invalid }: ValidationMessages = {
      required: t('common.validations.emailRequired'),
      invalid: t('common.validations.emailInvalid'),
    },
  ) => z.string({ message: required }).min(1, { message: required }).email({ message: invalid }),
  onlyLetters: (message: string) =>
    z
      .string()
      .min(1, { message })
      .regex(new RegExp(/^[a-zA-Z]+[-'s]?[a-zA-Z ]+$/), { message }),
  password: z
    .string()
    .regex(new RegExp('.*[A-Z].*'), t('common.validations.upperCase'))
    .regex(new RegExp('.*[a-z].*'), t('common.validations.lowerCase'))
    .regex(new RegExp('.*\\d.*'), t('common.validations.number'))
    .regex(new RegExp('.*[`~<>?,./!@#$%^&*()\\-_+="\'|{}\\[\\];:\\\\].*'), t('common.validations.specialCharacter'))
    .min(8, t('common.validations.minimumLength', { minimum: 8 })),
  amount: (
    { required, invalid }: ValidationMessages = {
      required: t('common.validations.required'),
      invalid: t('common.validations.invalid'),
    },
  ) =>
    z
      .string()
      .regex(new RegExp(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/), { message: invalid })
      .min(1, { message: required }),
  postcode: z
    .string({
      message: t('common.validations.postcode'),
    })
    .min(1, {
      message: t('common.validations.postcode'),
    })
    .regex(
      new RegExp(
        /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
      ),
      t('common.validations.postcode'),
    ),
  sortcode: z
    .string({
      message: t('common.validations.sortcode'),
    })
    .refine((value) => sortCodeRegex.test(value), t('common.validations.sortcode')),
};
