import { Avatar } from '@library/components/atoms/Avatar';
import { TableCell } from '@library/components/atoms/TableCell';
import { Label } from '@library/components/molecules';
import { CellContext } from '@tanstack/react-table';
import clsx from 'clsx';

import { SubscriptionOverview } from '@shared/data/types';

import { elipsis } from '../../../PatientTable/CellRenderers';

export const PatientRenderer = (props: CellContext<SubscriptionOverview, string>) => {
  const value = props.getValue();
  return (
    <TableCell>
      <div
        data-testid="patient-link"
        className={clsx('flex gap-2 items-center !cursor-pointer pointer-events-auto', elipsis)}
      >
        <div className="w-8">
          <Avatar text={value[0]} size={32} />
        </div>
        <div>
          <Label
            truncate={false}
            className="pointer-events-none whitespace-normal"
            appearance="secondary"
            size="small"
            labelText={value}
            labelStrong={false}
          />
        </div>
      </div>
    </TableCell>
  );
};
