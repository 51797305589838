interface HandleReactQueryErrorsArgs {
  status: number;
  errorMessage: string;
}

const MAGIC_LINK_INVALID = 'MAGIC_LINK_INVALID';
const MAGIC_LINK_EXPIRED = 'MAGIC_LINK_EXPIRED';
const MAGIC_LINK_DEVICE_MISMATCH = 'MAGIC_LINK_DEVICE_MISMATCH';

export const INVALID_LINK_MESSAGE = 'invalid-link';
export const EXPIRED_LINK_MESSAGE = 'expired-link';
export const DEVICE_MISMATCH_MESSAGE = 'device-mismatch';

export type LoginCodes = typeof INVALID_LINK_MESSAGE | typeof EXPIRED_LINK_MESSAGE | typeof DEVICE_MISMATCH_MESSAGE;

export const handleMagcLinkErrors = ({ errorMessage, status }: HandleReactQueryErrorsArgs) => {
  switch (errorMessage) {
    case MAGIC_LINK_INVALID:
      return window.location.assign(`/patient/login?type=${INVALID_LINK_MESSAGE}`);
    case MAGIC_LINK_EXPIRED:
      return window.location.assign(`/patient/login?type=${EXPIRED_LINK_MESSAGE}`);
    case MAGIC_LINK_DEVICE_MISMATCH:
      return window.location.assign(`/patient/login?type=${DEVICE_MISMATCH_MESSAGE}`);
  }
  if (status == 401) {
    return window.location.assign('/patient/login');
  }
};
