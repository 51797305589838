import { TableCell } from '@library/components/atoms/TableCell';
import { Tag } from '@library/components/molecules';
import { CellContext } from '@tanstack/react-table';

import { PatientOverview } from '@shared/data/types';

export const TreatmentRendererNewDS = (props: CellContext<PatientOverview, unknown>) => {
  const treatments = props.row.original.treatments;
  return (
    <TableCell>
      <div className="flex gap-2">
        {treatments.length
          ? treatments
              .slice(0, 2)
              .map((treatment) => <Tag size="medium" key={treatment.name} template="OUTLINE" text={treatment.name} />)
          : null}
        {treatments.length > 2 && <Tag size="medium" text={`+${treatments.length - 2}`} template="OUTLINE" />}
      </div>
    </TableCell>
  );
};
