import { Size } from '@library/types';

export const getSizeClasses = (size: Size) => {
  switch (size) {
    case 'small':
      return 'rounded-xl p-6';
    case 'medium':
      return 'rounded-2xl py-10 px-6';
    case 'large':
      return 'rounded-2xl py-[3.75rem] px-6';
  }
};

export const getHeadingVariant = (size?: Size) => {
  switch (size) {
    case 'small':
      return 'xxxSmall';
    case 'medium':
      return 'xxSmall';
    case 'large':
      return 'xSmall';
    default:
      return 'xxSmall';
  }
};

export const getParagraphSize = (size?: Size) => {
  switch (size) {
    case 'small':
      return 'small';
    case 'medium':
      return 'small';
    case 'large':
      return 'medium';
    default:
      return 'medium';
  }
};
