import { Dispatch, SetStateAction, useState } from 'react';
import { TableCell } from '@library/components/atoms/TableCell';
import { TableHeader } from '@library/components/molecules';
import { convertToCurrency } from '@library/utils';
import {
  CellContext,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { t } from 'i18next';

import { PayoutOverview } from '@shared/data/types';

type CellFn<T> = CellContext<PayoutOverview, T>;

interface Props {
  payoutsOverview: PayoutOverview[];
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
}

export const useTable = ({ payoutsOverview, sorting, setSorting }: Props) => {
  const [filter, setFilter] = useState('');
  const [pageIndex, setPageIndex] = useState(0);

  const createCell = (data: CellFn<string>) => <TableCell>{data.getValue()}</TableCell>;
  const createCurrencyCell = (data: CellFn<number>) => (
    <TableCell>
      <div className="!w-[7.25rem] justify-end flex">{convertToCurrency(data.getValue(), '£')}</div>
    </TableCell>
  );
  const columns: ColumnDef<PayoutOverview, unknown>[] = [
    {
      id: 'Month',
      accessorFn: (data: PayoutOverview) => {
        return new Date(data.month).toLocaleDateString('en-GB', {
          month: 'long',
          year: 'numeric',
        });
      },
      cell: createCell,
      size: 260,
      enableSorting: true,
      header: (props) => {
        return <TableHeader {...props} tooltipText={t('practice.plansDashboard.headerTooltip.month')} />;
      },
    },
    {
      id: 'Total collected',
      accessorFn: (data: PayoutOverview) => {
        return parseInt(data.totalCollected);
      },
      cell: createCurrencyCell,
      size: 260,
      header: (props) => {
        return <TableHeader {...props} tooltipText={t('practice.plansDashboard.headerTooltip.collectedPayments')} />;
      },
      enableSorting: true,
    },
  ];

  const table = useReactTable<PayoutOverview>({
    data: payoutsOverview,
    state: {
      sorting,
      globalFilter: filter,
      pagination: {
        pageSize: 7,
        pageIndex,
      },
      columnVisibility: {
        email: false,
      },
    },
    defaultColumn: {
      header: TableHeader,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columns,
  });

  return {
    table,
    setFilter,
    setSorting,
    setPageIndex,
  };
};
