import { CopyText } from '@library/components/atoms';

import { Button } from '@shared/components/Button';

export interface ReviewDetailsSection {
  title: string;
  content: string;
}

export interface ReviewDetailsProps {
  title: string;
  sections: ReviewDetailsSection[];
  setInReview: (inReview: boolean) => void;
}

export const ReviewDetails = ({ title, sections, setInReview }: ReviewDetailsProps) => (
  <div className={'flex flex-col gap-4 w-full'}>
    <div className="flex justify-between items-center">
      <CopyText variant="label" size="medium">
        {title}
      </CopyText>
      <Button
        text={'Edit'}
        variant="tertiary"
        className="!text-primary-main"
        onClick={() => {
          setInReview(false);
        }}
      />
    </div>
    {sections?.map((option) => (
      <div className="flex flex-col gap-2" key={option.title}>
        <CopyText variant="paragraph" size="medium">
          {option.title}
        </CopyText>
        <CopyText variant="paragraph" size="medium" colour="foregroundNeutralSecondary">
          {option.content}
        </CopyText>
      </div>
    ))}
  </div>
);

ReviewDetails.displayName = 'ReviewDetails';
