import React, { ChangeEvent, forwardRef, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { ErrorText, Icon, SelectorMenu, SelectorMenuProps } from '@library/components/atoms';
import { IconName } from '@library/components/atoms/Icon/utils';
import { getInputSizeClasses } from '@library/components/molecules/Input/utils';
import clsx from 'clsx';

import { elipsis } from '@practice/pages/PatientsPages/PatientsDashboard/PatientTable/CellRenderers';
import { useOnClickOutside } from '@shared/hooks/useOnClickOutside/useOnClickOutside';

import { InputContainer } from '../InputContainer';
import { Label } from '../Label';
import { Tag } from '../Tag';

export interface SelectorDropdownIcon {
  icons?: {
    open: IconName;
    close: IconName;
  };
  isOpen: boolean;
  handleClick?: () => void;
}
export interface Option {
  value: string;
  name: string;
  helperText?: string;
}

export interface SelectorProps {
  testId?: string;
  options: Option[];
  placeholder?: string;
  className?: string;
  onChange?: (selection: Option) => void;
  size: 'small' | 'medium' | 'large';
  menuSize?: 'small' | 'medium' | 'large';
  labelText?: string;
  labelTooltip?: ReactNode;
  helperText?: string;
  disabled?: boolean;
  isMultiSelect?: boolean;
  isTags?: boolean;
  selected?: Option[];
  hideArrowIcons?: boolean;
  leftIcon?: IconName;
  leftIconViewbox?: string;
  icons?: {
    open: IconName;
    close: IconName;
  };
  errorTitle?: string;
  errorDescription?: string;
  validationError?: string;
  /** index of the option to be selected by default */
  defaultSelectedIndex?: number;
  hideScrollbar?: boolean;
  triggeredOpen?: boolean;
  dropdownAlignment?: 'left' | 'right';
  externalIndex?: number;
  setExternalIndex?: React.Dispatch<SetStateAction<number>>;
  searchTerm?: string;
  setSearchTerm?: (searchTerm: string) => void;
  /** make the selector input full width */
  full?: boolean;
  showMenu?: boolean;
  hasError?: boolean;
  iconColour?: string;
  selectorMenuClassName?: SelectorMenuProps['className'];
  testPrefix?: string;
}

export const selectorClasses = `
  border-0
  !ring-inset
  ring-borderNeutralDefault ring-1
  hover:ring-borderBrandDefault hover:ring-2
  focus:ring-borderBrandDefault focus:ring-[3px]
  bg-white
  text-foregroundNeutralPrimary
  rounded-lg
  inline-block
  w-full
  text-foregroundNeutralTertiary
  hover:text-foregroundNeutralSecondary
  focus:text-foregroundNeutralPrimary
`;

const disabledClasses = `
  !bg-backgroundNeutralSoft
  !ring-borderNeutralDefault
  !ring-1
  !text-foregroundNeutralQuaternary
`;

export const Selector: React.FC<SelectorProps> = forwardRef(
  (
    {
      testId,
      options,
      placeholder,
      className,
      onChange,
      size,
      menuSize,
      labelText,
      labelTooltip,
      helperText,
      disabled,
      isMultiSelect,
      isTags,
      selected,
      icons,
      errorTitle,
      errorDescription,
      /* Use this field to display an error message when the component is a simple dropdown */
      validationError,
      hideArrowIcons,
      leftIcon,
      leftIconViewbox = '',
      /**
       * index of the option to be selected by default, changes to this prop will cause a new option to be selected
       * */
      defaultSelectedIndex,
      hideScrollbar,
      triggeredOpen = false,
      dropdownAlignment,
      externalIndex,
      setExternalIndex,
      setSearchTerm,
      searchTerm,
      /** If false selector will have a max width of '26.25rem' */
      full = true,
      showMenu = true,
      hasError,
      iconColour,
      selectorMenuClassName,
      testPrefix,
    }: SelectorProps,
    _,
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(defaultSelectedIndex ?? null);
    const [selectedOptions, setSelectedOptions] = useState<Option[] | undefined>(selected || []);
    const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
    const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
    const [textSearchValue, setTextSearchValue] = useState<string | undefined>('');
    const [showTags, setShowTags] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const textSearchRef = useRef<HTMLInputElement>(null);
    const tagContainerRef = useRef<HTMLDivElement>(null);

    const currentIndex = externalIndex !== undefined ? externalIndex : selectedIndex;

    useEffect(() => {
      setIsOpen(Boolean(triggeredOpen));
    }, [triggeredOpen]);

    useEffect(() => {
      setSelectedIndex(defaultSelectedIndex ?? null);
    }, [options, defaultSelectedIndex]);

    const handleSearchFocus = () => {
      if (textSearchRef.current) {
        textSearchRef.current.focus();
      }
    };

    useEffect(() => {
      setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
      setSelectedOptions(selected!);
    }, [selected]);

    const handleToggleDropdown = () => {
      setIsOpen((prev) => !prev);
      setTextSearchValue('');
      handleSearchFocus();
    };

    const handleTextSearch = (event: ChangeEvent) => {
      setIsOpen(true);
      setShowTags(false);
      setHighlightedIndex(0);
      const value = (event.target as HTMLInputElement).value;
      if (setSearchTerm) {
        setSearchTerm(value);
      } else {
        setTextSearchValue(value);
        if (value === '') {
          setFilteredOptions(options);
        } else {
          const filteredOptions = options.filter((obj) => obj.name.toLowerCase().includes(value.toLowerCase()));
          setFilteredOptions(filteredOptions);
        }
      }
    };

    const handleOptionClick = (index: number) => {
      if (isMultiSelect) {
        const isExisting = selectedOptions?.find((obj) => obj.value === filteredOptions[index].value);
        if (isExisting) {
          const updatedOptions = selectedOptions?.filter((obj) => obj.value !== filteredOptions[index].value);
          setSelectedOptions(updatedOptions);
        } else {
          setSelectedOptions((prevValue) => {
            return [...(prevValue || []), filteredOptions[index]];
          });
        }
        handleSearchFocus();
        setFilteredOptions(options);
        setTextSearchValue('');
      } else {
        if (setExternalIndex) {
          setExternalIndex(index);
        } else {
          setSelectedIndex(index);
        }
        setIsOpen(false);
      }
      onChange?.(filteredOptions[index]);
      setShowTags(false);
    };

    const handleMenuKeys = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!isOpen) {
        if (event.key === 'ArrowDown' || event.key === 'Enter' || event.key === ' ') {
          setIsOpen(true);
          setHighlightedIndex(0);
          event.preventDefault();
        }
      } else {
        switch (event.key) {
          case 'ArrowDown':
            setHighlightedIndex((prev) => (prev === null || prev === filteredOptions.length - 1 ? 0 : prev + 1));
            event.preventDefault();
            break;
          case 'ArrowUp':
            setHighlightedIndex((prev) => (prev === null || prev === 0 ? filteredOptions.length - 1 : prev - 1));
            event.preventDefault();
            break;
          case 'Enter':
            if (highlightedIndex !== null) {
              handleOptionClick(highlightedIndex);
              setTextSearchValue('');
              setFilteredOptions(options);
            }
            break;
          case 'Escape':
            setIsOpen(false);
            setHighlightedIndex(null);
            break;
        }
      }
    };

    const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      switch (event.key) {
        case 'Backspace':
          if (textSearchValue?.length === 0) {
            setSelectedOptions(selectedOptions?.slice(0, -1));
            if (selectedOptions?.length) onChange?.(selectedOptions?.[selectedOptions.length - 1]);
          }
          break;
      }
      handleMenuKeys(event);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === ' ' && !setSearchTerm) {
        event.preventDefault();
      }
      handleMenuKeys(event);
    };

    const getSelectorClasses = () => {
      switch (size) {
        case 'small':
          return 'px-2.5';
        case 'medium':
          return 'px-3';
        case 'large':
          return 'px-4';
      }
    };

    const getErrorClasses = () => {
      return validationError || hasError
        ? '!border-state-error !border-solid !border rounded-lg text-foregroundErrorPrimary !border-[3px]'
        : '';
    };

    const handleRemoveTag = (index: number) => {
      setSelectedOptions((prev) => {
        return prev?.filter((_, i) => i !== index);
      });
      handleSearchFocus();
      if (selectedOptions) onChange?.(selectedOptions[index]);
    };

    const SelectorDropdownIcon = ({ icons, isOpen, handleClick }: SelectorDropdownIcon) => {
      const openIcon = textSearchValue !== '' ? 'close' : icons?.close || 'chevron-up';
      const closeIcon = icons?.open || 'chevron-down';
      return (
        <div className="pointer-events-none pl-2">
          <Icon
            color="text-foregroundNeutralQuaternary"
            iconName={isOpen ? openIcon : closeIcon}
            size={size == 'small' ? 'small' : 'medium'}
            handleOnClick={() => handleClick}
          />
        </div>
      );
    };

    useOnClickOutside(dropdownRef, () => {
      if (isOpen) {
        setIsOpen(false);
        setHighlightedIndex(null);
      }
    });

    return (
      <div className="flex flex-col gap-2.5">
        {(labelText || helperText) && (
          <Label
            disabled={disabled}
            appearance="secondary"
            labelText={labelText}
            helperText={helperText}
            size={'small'}
            labelStrong={false}
            tooltip={labelTooltip}
          />
        )}
        <div
          ref={dropdownRef}
          className={clsx(
            'relative outline-none w-full',
            currentIndex !== null && currentIndex > -1 && '!text-black',
            className,
            selectorClasses,
            disabled ? disabledClasses : 'cursor-pointer',
            !full && 'max-w-[26.25rem]',
          )}
          tabIndex={0}
          onClick={!disabled ? handleToggleDropdown : undefined}
          onKeyDown={!disabled ? (isMultiSelect ? handleSearchKeyDown : handleKeyDown) : undefined}
          role="combobox"
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          aria-controls="dropdown-listbox"
          aria-labelledby="dropdown-label"
          data-testid={`${testId}-button`}
          aria-disabled={disabled}
        >
          <div
            className={clsx(
              'border-0 w-full outline-none flex justify-between items-center relative',
              getSelectorClasses(),
              getInputSizeClasses(size),
              getErrorClasses(),
              selectedOptions && selectedOptions?.length > 0 && 'pl-2',
              !full && 'max-w-[46.25rem]',
            )}
            id="dropdown-label"
            aria-live="polite"
          >
            {leftIcon && (
              <Icon
                color={iconColour || 'text-foregroundNeutralPrimary'}
                iconName={leftIcon}
                size={size == 'small' ? 'small' : 'medium'}
                viewBox={leftIconViewbox}
                className="mr-2"
              />
            )}
            {isMultiSelect || setSearchTerm ? (
              <InputContainer inputSize={size}>
                <div
                  ref={tagContainerRef}
                  className="overflow-x-scroll no-scrollbar h-full w-full flex items-center justify-between gap-1"
                >
                  {
                    <div className={clsx(!selectedOptions && 'w-full', 'inline-flex items-center gap-x-2')}>
                      {selectedOptions && selectedOptions.length > 0 && (
                        <>
                          {isTags && (
                            <>
                              {selectedOptions.length < 2 || showTags ? (
                                selectedOptions.map((option, i) => (
                                  <Tag
                                    key={option.value as string}
                                    size={'large'}
                                    text={option.name}
                                    icon="close-big"
                                    handleRemoveTag={() => handleRemoveTag(i)}
                                    className="text-foregroundNeutralPrimary"
                                  />
                                ))
                              ) : (
                                <Tag
                                  className="pointer-events-auto cursor-pointer !bg-backgroundNeutralSubtle text-foregroundNeutralPrimary"
                                  handleOnClick={() => setShowTags(true)}
                                  text={`${selectedOptions.length} treatments`}
                                  size="large"
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                      <input
                        ref={textSearchRef}
                        data-testid={`${testPrefix || ''}selector-text-search`}
                        type="text"
                        value={setSearchTerm ? searchTerm : textSearchValue}
                        placeholder={placeholder}
                        className={clsx(
                          selectedOptions?.length
                            ? 'placeholder:text-foregroundNeutralTertiary ml-2'
                            : 'placeholder:text-foregroundNeutralTertiary',
                          'focus:text-foregroundNeutralPrimary w-full bg-transparent border-0 ring-0 active:ring-0 focus:ring-0 p-0',
                        )}
                        onChange={handleTextSearch}
                      />
                    </div>
                  }
                </div>
              </InputContainer>
            ) : (
              <span data-testid="selected-option-name" className={elipsis}>
                {options[currentIndex ?? defaultSelectedIndex ?? -1]?.name || placeholder}
              </span>
            )}
            {!hideArrowIcons && (
              <SelectorDropdownIcon
                icons={icons}
                isOpen={isOpen!}
                handleClick={isMultiSelect ? handleToggleDropdown : undefined}
              />
            )}
          </div>
          {isOpen && showMenu && (
            <SelectorMenu
              isMultiSelect={isMultiSelect}
              alignment={dropdownAlignment}
              handleOptionClick={handleOptionClick}
              highlightedIndex={highlightedIndex}
              setHighlightedIndex={setHighlightedIndex}
              options={filteredOptions}
              selectedIndex={currentIndex}
              selectedOptions={selectedOptions}
              size={menuSize || size}
              errorTitle={errorTitle}
              errorDescription={errorDescription}
              hideScrollbar={hideScrollbar}
              fullWidth
              testId={testId}
              className={selectorMenuClassName}
            />
          )}
        </div>
        {validationError && <ErrorText icon="info" text={validationError} />}
      </div>
    );
  },
);

Selector.displayName = 'Selector';
