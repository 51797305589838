import { createContext, PropsWithChildren, ReactNode, useState } from 'react';
import { CopyText } from '@library/components/atoms';
import { CloseIconButton } from '@library/components/atoms/CloseIconButton';
import { Button } from '@library/components/molecules';
import clsx from 'clsx';

const transitionClasses = `transform transition-all duration-300 ease-in-out`;
const overlayClasses = `fixed inset-0 bg-black bg-opacity-20 z-modalOverlay`;
const panelClasses = `w-full md:max-w-[48rem] right-0 z-modalPanel absolute bg-white overflow-y-auto rounded-3xl`;
const iconCloseName = `absolute top-3 right-3`;
const headerClasses = `flex justify-between top-0 sticky bg-gradient-to-b from-white from-90% z-modalHeader`;

type ModalContextType = {
  closeModal: () => void;
};

export const ModalContext = createContext<ModalContextType>({
  closeModal: () => {},
});

export const modalContentPadding = `px-6 md:px-10 pb-10`;

export type ModalDialogProps = {
  isOpen?: boolean;
  isDesktop?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  heading?: string;
  centered?: boolean;
  onClose?: () => void;
};

export const ModalDialog = ({
  isDesktop,
  isOpen,
  setIsOpen,
  heading,
  centered,
  children,
  onClose,
}: ModalDialogProps & PropsWithChildren) => {
  const [modalAnimation, setModalAnimation] = useState(
    isDesktop ? 'animate-modalPanelEntry' : 'animate-modalPanelEntryMobile',
  );
  const [overlayAnimation, setOverlayAnimation] = useState(
    isOpen ? 'animate-modalOverlayEntry' : 'animate-modalOverlayExit',
  );

  const closeModal = () => {
    setModalAnimation(isDesktop ? 'animate-modalPanelExit' : 'animate-modalPanelExitMobile');
    setOverlayAnimation('animate-modalOverlayExit');
    setTimeout(() => {
      setIsOpen(false);
      setModalAnimation(isDesktop ? 'animate-modalPanelEntry' : 'animate-modalPanelEntryMobile');
      setOverlayAnimation('animate-modalOverlayEntry');
      onClose?.();
    }, 300);
  };

  return (
    <div
      data-testid="modal-wrapper"
      className={clsx(transitionClasses, isOpen ? 'visible' : 'invisible', 'fixed inset-0 z-50')}
    >
      {/* overlay */}
      <div
        data-testid="modal-overlay"
        onClick={closeModal}
        className={clsx(transitionClasses, overlayAnimation, isOpen && overlayClasses)}
      />
      {/* panel */}
      {centered ? (
        <CenteredModal heading={heading} closeModal={closeModal}>
          {children}
        </CenteredModal>
      ) : (
        <Drawer modalAnimation={modalAnimation} isDesktop={isDesktop} closeModal={closeModal} heading={heading}>
          {children}
        </Drawer>
      )}
    </div>
  );
};

const Drawer = ({
  modalAnimation,
  isDesktop,
  closeModal,
  heading,
  children,
}: {
  modalAnimation: string;
  isDesktop?: boolean;
  closeModal: () => void;
  heading?: string;
  children: ReactNode;
}) => {
  return (
    <div
      data-testid="modal-panel"
      className={clsx(modalAnimation, isDesktop ? 'inset-y-0' : 'top-40 bottom-0', panelClasses)}
    >
      <div className="relative w-full h-full">
        <div className={clsx(headerClasses)}>
          <CloseIconButton
            className={clsx(iconCloseName)}
            testId="modal-panel-close"
            handleOnClick={closeModal}
            size={isDesktop ? 'medium' : 'small'}
          />
          <div className="flex px-6 md:px-10 pt-10">
            <CopyText variant="heading" size={isDesktop ? 'small' : 'xSmall'} testId="modal-header">
              {heading}
            </CopyText>
          </div>
        </div>
        <div data-testid="modal-content" className={'flex justify-center items-start px-4'}>
          <ModalContext.Provider value={{ closeModal }}>{children}</ModalContext.Provider>
        </div>
      </div>
    </div>
  );
};

const CenteredModal = ({
  heading,
  closeModal,
  children,
}: {
  heading?: string;
  closeModal: () => void;
  children: ReactNode;
}) => {
  return (
    <div className="absolute right-1/2 top-1/2">
      <div
        data-testid="modal-centered"
        className={'z-modalPanel relative bg-white overflow-y-auto rounded-2xl left-1/2 bottom-32 p-2 !w-[480px]'}
      >
        <div className="relative">
          {/* header */}
          <div className={'w-full flex justify-between items-center'}>
            <CopyText variant="heading" size="xxSmall" className="p-4">
              {heading}
            </CopyText>
            {/* close icon */}
            <Button
              mode={'accent'}
              appearance={'ghost'}
              size={'small'}
              leftIcon="close"
              onClick={closeModal}
              className={'!rounded-3xl'}
            />
          </div>
          <div data-testid="modal-content" className={'flex justify-center items-start p-4'}>
            <ModalContext.Provider value={{ closeModal }}>{children}</ModalContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};
