import { HTMLAttributes, PropsWithChildren } from 'react';
import clsx from 'clsx';

import { useMediaQuery } from '@shared/hooks';

export const PageContentWrapper = ({ children, className }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  const isMobile = useMediaQuery('max-width: 767px');
  return (
    <div className={clsx('w-full max-w-[40.5rem] mx-auto gap-10  mb-24', isMobile && 'px-6 pt-24', className)}>
      {children}
    </div>
  );
};

export const ContentBlockWrapper = ({ children, className }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  const isMobile = useMediaQuery('max-width: 767px');
  return <div className={clsx('w-full md:max-w-[40.5rem] mx-auto gap-10', isMobile && '', className)}>{children}</div>;
};
